import dishwasher from "../../assets/assetIcons/dishwasher.svg";
import shower from "../../assets/assetIcons/shower.svg";
import tap from "../../assets/assetIcons/tap.svg";
import valve from "../../assets/assetIcons/valve.svg";
import washing_machine from "../../assets/assetIcons/washing-machine.svg";

export const viewerConfig = {
  icons: {
    DISHWASHER: dishwasher,
    SHOWER: shower,
    TAP: tap,
    VALVE: valve,
    WASHING_MACHINE: washing_machine,
  },
  colors: {
    BLUE: ["#2264d1", "#EBEFF5"],
    BLACK: ["#000000", "#E8E8E8"],
    GREY: ["#CCCCCC", "#FFFFFF"],
    GREEN: ["#64B90C", "#F0F8E7"],
    ORANGE: ["#EE7203", "#FFF8F1"],
    RED: ["#C10000", "#FFF5F5"],
  },
};

export const toLayoutConfig = (layout) => ({
  background: layout.layoutUrl,
  pois: layout.pois
    .filter((poi) => poi.location.set)
    .map((poi) => ({
      x: poi.location.x,
      y: poi.location.y,
      id: poi.objectOfInterestId,
      name: poi.objectOfInterestName,
      alert: poi.alertLevel ?? "NONE",
      color: poi.color,
      icon: poi.icon ?? "TAP",
      tasksCount: poi.tasksCount,
    })),
});

export const toLayout = (layout, pois) => {
  return {
    poiUpdates: layout.pois
      .filter((poi) => Object.keys(pois).includes(poi.objectOfInterestId))
      .map((poi) => ({
        location: {
          x: pois[poi.objectOfInterestId].x,
          y: pois[poi.objectOfInterestId].y,
        },
        objectOfInterestId: poi.objectOfInterestId,
        objectOfInterestType: poi.objectOfInterestType,
      })),
  };
};

export const getFilterOptions = (pois, assetTypes) => {
  let assetTypeOptions = [];
  let taskTypeOptions = [];
  let alertOptions = [];

  pois.forEach((poi) => {
    if (
      !assetTypeOptions
        .map((option) => option.value)
        .includes(poi.assetPrototypeId)
    ) {
      assetTypeOptions.push({
        value: poi.assetPrototypeId,
        label: poi.assetPrototypeId
          ? assetTypes.find((x) => x.id === poi.assetPrototypeId).name
          : poi.assetPrototypeId,
        global: poi.assetPrototypeId
          ? assetTypes.find((x) => x.id === poi.assetPrototypeId).global
          : false,
      });
    }

    if (poi.tasksPrototypes && poi.tasksPrototypes.length) {
      poi.tasksPrototypes.forEach((proto) => {
        if (
          !taskTypeOptions.map((option) => option.value).includes(proto.id) &&
          !proto.deprecated
        ) {
          taskTypeOptions.push({
            value: proto.id,
            label: proto.name,
            global: proto.isGlobal,
          });
        }
      });
    }

    if (!alertOptions.map((option) => option.value).includes(poi.alertLevel)) {
      alertOptions.push({
        value: poi.alertLevel,
        label: poi.alertLevel,
      });
    }
  });

  return {
    assetType: assetTypeOptions,
    taskType: taskTypeOptions,
    alert: alertOptions,
  };
};

export const getUnsetPois = (layout) =>
  layout.pois
    .filter((poi) => !poi.location.set)
    .map((poi) => ({
      x: poi.location.x,
      y: poi.location.y,
      id: poi.objectOfInterestId,
      name: poi.objectOfInterestName,
      alert: poi.alertLevel ?? "NONE",
      icon: poi.icon ?? "TAP",
      color: poi.color,
      tasksCount: poi.tasksCount,
    }));
