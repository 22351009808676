import React, { useState } from "react";
import styles from "./RecurrenceTasks.module.scss";
import RecurrenceTasksItem from "./RecurrenceTasksItem";
import DeleteGroupTasksModal from "./DeleteGroupTasksModal";
import GroupTasksModal from "./GroupTasksModal";
import { useSelector } from "react-redux";
import ActivityIndicator from "../ActivityIndicator";

const RecurrenceTasks = ({ items, filters }) => {
  const loading = useSelector((state) => state.tasks.recurrenceLoading);
  const [openEditGroupTasksModal, setEditGroupTasksModalOpen] = useState(false);
  const [openDeleteGroupTasksModal, setDeleteGroupTasksModalOpen] =
    useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const showGroupDeleteModal = (e, group) => {
    e.stopPropagation();
    setDeleteGroupTasksModalOpen(true);
    setSelectedGroup(group);
  };

  const showGroupEditModal = (e, group) => {
    e.stopPropagation();
    setEditGroupTasksModalOpen(true);
    setSelectedGroup(group);
  };

  return (
    <>
      {loading && <ActivityIndicator />}
      <div className={styles.task_list_container}>
        {items.map((item) => (
          <RecurrenceTasksItem
            key={item.id}
            taskPool={item}
            onEditClick={showGroupEditModal}
            onDeleteClick={showGroupDeleteModal}
          />
        ))}

        {!!openEditGroupTasksModal && (
          <GroupTasksModal
            key="edit-group"
            group={selectedGroup}
            isOpen={openEditGroupTasksModal}
            onClose={() => {
              setEditGroupTasksModalOpen(false);
              setSelectedGroup(null);
            }}
            recurrence
            recurrenceFilters={filters}
          />
        )}
      </div>

      {!!openDeleteGroupTasksModal && (
        <DeleteGroupTasksModal
          key="delete-group"
          group={selectedGroup}
          isOpen={openDeleteGroupTasksModal}
          onClose={() => {
            setDeleteGroupTasksModalOpen(false);
            setSelectedGroup(null);
          }}
          recurrence
          filters={filters}
        />
      )}
    </>
  );
};

export default RecurrenceTasks;
