import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import {
  FieldLabel,
  Form,
  FormControl,
  FieldError,
  FieldSelect,
  DatePicker,
} from "../Input";
import Modal from "../Modal";
import TaskPrototypeForm from "../Configuration/TaskPrototypeForm";
import { I18nContext } from "../../i18n/I18nContext";
import { useDispatch, useSelector } from "react-redux";
import {
  addRecurrenceTask,
  fetchAssetDetails,
  fetchTasksList,
  fetchRecurrenceTasksList,
  fetchAssetsNameList,
} from "../../redux";
import { TaskState } from "../../constants/Tasks";
import styles from "./TasksModal.module.scss";
import { differenceInCalendarDays } from "date-fns";
import { createAssetDisplayedPropertiesString } from "../../helpers/helpers";
import useDeepCompareEffect from "use-deep-compare-effect";
import ActivityIndicator from "../ActivityIndicator";

const basicValues = {
  templateId: "",
  type: "",
  recurringInterval: null,
  intervalUnit: "",
  assetId: "",
  startDate: new Date(),
  finishRecurrenceAt: null,
};

const validationSchema = (t) =>
  Yup.object({
    assetId: Yup.string().required(t("please select one of the options")),
    templateId: Yup.string().required(t("please select one of the options")),
    startDate: Yup.date()
      .required(t("start date is required"))
      .test(
        "startDate",
        t("start date cannot be in the past"),
        (value) => differenceInCalendarDays(value, new Date()) >= 0
      ),
    finishRecurrenceAt: Yup.date()
      .nullable()
      .default(null)
      .when(
        "startDate",
        (startDate, Yup) =>
          startDate &&
          Yup.min(startDate, t("end date cannot be before start date"))
      ),
  });

const RecurringTaskModal = ({ onClose, isOpen, currentDetails }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [selected, setSelectedPrototype] = useState(null);
  const [edited, setEditedPrototype] = useState(null);
  const [initialValues, setInitialValues] = useState(basicValues);
  const [taskPrototypeErrors, setTaskPrototypeErrors] = useState(null);
  const [templatedNotSelected, setTemplateNotSelected] = useState(false);

  const taskPrototypes = useSelector((state) => state.taskPrototypes.items);
  const assetNames = useSelector((state) => state.assets.items ?? []);
  const loadingAssetNames = useSelector(
    (state) => state.assets.loadingAssetNames
  );
  const allChosen = useSelector((state) => state.buildings.choosenBuildings);

  const clearForm = (formik) => {
    setSelectedPrototype(null);
    setEditedPrototype(null);
    setTemplateNotSelected(false);

    formik?.resetForm();
  };

  useDeepCompareEffect(() => {
    if (!currentDetails) {
      if (allChosen.length > 0) {
        dispatch(fetchAssetsNameList(allChosen.map((el) => el.id)));
      } else {
        dispatch(fetchAssetsNameList());
      }
    }
  }, [allChosen]);

  useEffect(() => {
    if (currentDetails) {
      setInitialValues({ ...basicValues, assetId: currentDetails.id });
    }
  }, [currentDetails, isOpen]);

  const handleSubmit = async (values, formik) => {
    if (Object.keys(taskPrototypeErrors).length > 0) {
      return;
    }
    const template = edited ?? selected;
    const assetId = currentDetails?.id ? currentDetails.id : values.assetId;

    await dispatch(
      addRecurrenceTask(t, {
        prototypeId: template.id,
        assetId,
        startDate: values.startDate,
        finishRecurrenceAt: values.finishRecurrenceAt,
      })
    );
    await dispatch(
      fetchTasksList(
        TaskState.TODO,
        allChosen.map((el) => el.id)
      )
    );

    if (allChosen.length > 0) {
      await dispatch(fetchRecurrenceTasksList(allChosen.map((el) => el.id)));
    } else {
      await dispatch(fetchRecurrenceTasksList());
    }

    await dispatch(fetchAssetDetails(assetId));
    clearForm(formik);
    onClose();
  };

  const handleClose = () => {
    clearForm();
    onClose();
  };

  const customAssetOptionLabel = (option) => {
    const isPath = !!option.asset?.path && option.asset?.path.length > 1;
    const isProperties =
      !!option.asset?.displayedProperties &&
      !!option.asset?.displayedProperties.length;
    return (
      <div className={styles.assetOptionLabel}>
        <span className={styles.assetOptionPath}>
          {isPath ? option.asset.path.slice(0, -1).join(" / ") + " / " : ""}
        </span>
        <span className={styles.assetOptionName}>
          {option.label}
          {isProperties
            ? createAssetDisplayedPropertiesString(option.asset, t)
            : ""}
        </span>
      </div>
    );
  };

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={handleClose}
      divider
      lengthInfo
      title={t("recurring task")}
      form="recurring-task-form"
      pending={!!loadingAssetNames}
    >
      {!!loadingAssetNames && <ActivityIndicator withWrapper />}
      {!loadingAssetNames && (
        <Form
          id="recurring-task-form"
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema(t)}
        >
          {(props) => {
            if (templatedNotSelected && !props.touched.templateId) {
              props.setFieldTouched("templateId");
            }

            return (
              <>
                <FormControl>
                  <FieldLabel>
                    {t("assign task to asset")}
                    <span className={styles.red}> *</span>
                  </FieldLabel>
                  <div>
                    <FieldSelect
                      size="s"
                      isMultiple={false}
                      name="assetId"
                      disabled={currentDetails?.id}
                      singleValue={currentDetails?.id}
                      options={
                        !currentDetails
                          ? assetNames
                              .map((asset) => ({
                                label: asset.name,
                                value: asset.id,
                                asset: asset,
                              }))
                              .sort((a, b) =>
                                a.asset.path
                                  .join()
                                  .localeCompare(b.asset.path.join())
                              )
                          : [
                              {
                                label: currentDetails.name,
                                value: currentDetails.id,
                                asset: currentDetails,
                              },
                            ]
                      }
                      placeholder={t("assign task to asset")}
                      customLabel={customAssetOptionLabel}
                    />
                  </div>
                  <FieldError name="assetId" />
                </FormControl>
                <div className={styles.fullRow}>
                  <FormControl>
                    <FieldLabel>
                      {t("task template")}
                      <span className={styles.red}> *</span>
                    </FieldLabel>
                    <div className={styles.width}>
                      <FieldSelect
                        size="s"
                        name="templateId"
                        options={taskPrototypes
                          .filter(
                            (taskProto) =>
                              taskProto.recurrence && !taskProto.deprecated
                          )
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((proto) => ({
                            label: proto.name,
                            value: proto.id ? proto.id : "",
                            global: proto.global,
                          }))}
                        onChange={(templateId) =>
                          setSelectedPrototype(
                            taskPrototypes.find(
                              (template) => template.id === templateId
                            )
                          )
                        }
                        placeholder={t("task")}
                      />
                    </div>
                    <FieldError name="templateId" />
                  </FormControl>
                </div>
                <div className={styles.fullRowLeft}>
                  <FormControl>
                    <FieldLabel>
                      {t("start date")} <span className={styles.red}>*</span>
                    </FieldLabel>
                    <DatePicker name="startDate" withTime={false} />
                    <FieldError name="startDate" />
                  </FormControl>
                  <FormControl>
                    <FieldLabel>{t("end date")}</FieldLabel>
                    <DatePicker
                      name="finishRecurrenceAt"
                      withTime={false}
                      noDefaultValue
                    />
                    <FieldError name="finishRecurrenceAt" />
                  </FormControl>
                </div>
              </>
            );
          }}
        </Form>
      )}
      {selected && (
        <TaskPrototypeForm
          noTaskTypeName={true}
          readOnly={true}
          allowEditingTags={false}
          formId={"add-task-form"}
          taskPrototype={selected}
          onEdit={setEditedPrototype}
          onErrors={setTaskPrototypeErrors}
          recurranceRequired={true}
          disabled
          noRulesEditor={true}
          addTask={true}
        />
      )}
    </Modal>
  );
};

export default RecurringTaskModal;
