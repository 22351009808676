import React, { useContext, useEffect, useState } from "react";
import Button from "../Button";
import Modal from "../Modal";
import * as Yup from "yup";
import PropTypes from "prop-types";
import {
  FieldError,
  FieldInput,
  FieldLabel,
  FieldSelect,
  DatePicker,
  Form,
  FormControl,
  Checkbox,
} from "../Input";
import { I18nContext } from "../../i18n/I18nContext";

import styles from "./CompanyFormModal.module.scss";
import { useDispatch } from "react-redux";
import { addCompany, editCompany, fetchRegisteredCompanies } from "../../redux";

const defaultState = {
  name: "",
  privileged: false,
  ref: "",
  debtor: "",
  licenseAmount: 0,
  licenseStartDate: new Date(),
  noExpireDate: true,
  licenseExpireDate: new Date("December 31, 2099 23:59:59"),
};

const CompanyFormModal = ({ onClose, isOpen, company, companies }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();

  const [initialValuesForm, setInitialValues] = useState(defaultState);

  // const privilegedOptions = [
  //   {
  //     value: true,
  //     label: t("yes"),
  //   },
  //   {
  //     value: false,
  //     label: t("no"),
  //   },
  // ];

  const validationSchema = Yup.object({
    name: Yup.string()
      .required(t("missing company name"))
      .test({
        message: t("name must be unique"),
        test: (name) =>
          !companies
            .filter((item) => item.name !== company?.name)
            .map((item) => item.name)
            .includes(name),
      }),
    privileged: Yup.bool().required(t("please choose one option")),
    licenseExpireDate: Yup.date().when(
      "licenseStartDate",
      (licenseStartDate, Yup) =>
        licenseStartDate &&
        Yup.min(
          licenseStartDate,
          t("license end date cannot be before license start date")
        )
    ),
  });

  useEffect(() => {
    if (company) {
      setInitialValues({
        name: company.name,
        privileged: company.privileged,
        ref: company.referenceNumber,
        debtor: company.debtorNr,
        licenseAmount: company.license?.licenseAmount || 0,
        licenseStartDate: company.license?.licenseStartDate || new Date(),
        noExpireDate:
          company.license?.licenseExpireDate === "2099-12-31T00:00:00",
        licenseExpireDate: company.license?.licenseExpireDate || new Date(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const handleClear = () => {
    setInitialValues(defaultState);
    onClose();
  };

  const handleSubmit = async (values, { resetForm }) => {
    if (company) {
      await dispatch(
        editCompany(t, {
          id: company.id,
          name: values.name,
          // privileged: values.privileged,
          referenceNumber: values.ref,
          debtorNr: values.debtor,
          licenseAmount: values.licenseAmount,
          licenseStartDate: values.licenseStartDate,
          licenseExpireDate: values.noExpireDate
            ? new Date("December 31, 2099 23:59:59")
            : values.licenseExpireDate,
        })
      );
    } else {
      await dispatch(
        addCompany(t, {
          name: values.name,
          privileged: values.privileged,
          referenceNumber: values.ref,
          debtorNr: values.debtor,
          licenseAmount: values.licenseAmount,
          licenseStartDate: values.licenseStartDate,
          licenseExpireDate: values.noExpireDate
            ? new Date("December 31, 2099 23:59:59")
            : values.licenseExpireDate,
        })
      );
    }

    dispatch(fetchRegisteredCompanies());

    resetForm();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={company ? t("edit company") : t("create company")}
      add
      form="company-form"
      footer={
        <div className={styles.button_section}>
          <Button
            onClick={handleClear}
            type="reset"
            form={"company-form"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          {!company && (
            <Button
              className={styles.btn_separator}
              type="submit"
              variant="orange"
              size="s"
              form={"company-form"}
            >
              {t("add")}
            </Button>
          )}

          {company && (
            <Button
              className={styles.btn_separator}
              type="submit"
              variant="orange"
              size="s"
              form={"company-form"}
            >
              {t("save changes", "title")}
            </Button>
          )}
        </div>
      }
    >
      <Form
        id="company-form"
        onSubmit={handleSubmit}
        initialValues={initialValuesForm}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values }) => {
          return (
            <>
              <FormControl>
                <FieldLabel>
                  {t("name")}
                  <span className={styles.red}>*</span>
                </FieldLabel>
                <FieldInput size="l" name="name" type="text" />
                <FieldError name="name" />
              </FormControl>

              {/*<FormControl className={styles.dropdown}>*/}
              {/*  <FieldLabel>*/}
              {/*    {t("privileged")}*/}
              {/*    <span className={styles.red}>*</span>*/}
              {/*  </FieldLabel>*/}
              {/*  <FieldSelect*/}
              {/*    name="privileged"*/}
              {/*    size="s"*/}
              {/*    disabled={company}*/}
              {/*    options={privilegedOptions}*/}
              {/*  />*/}
              {/*  <FieldError name="privileged" />*/}
              {/*</FormControl>*/}
              <FormControl>
                <FieldLabel>{t("reference number")}</FieldLabel>
                <FieldInput size="l" name="ref" type="text" />
              </FormControl>
              <FormControl>
                <FieldLabel>{t("debtor number")}</FieldLabel>
                <FieldInput size="l" name="debtor" type="text" />
              </FormControl>
              <FormControl>
                <FieldLabel>{t("license amount")}</FieldLabel>
                <FieldInput size="l" name="licenseAmount" type="number" />
              </FormControl>
              <FormControl>
                <FieldLabel>
                  {t("licenses start date")}{" "}
                  <span className={styles.red}>*</span>
                </FieldLabel>
                <DatePicker name="licenseStartDate" />
                <FieldError name="licenseStartDate" />
              </FormControl>
              <Checkbox
                disabled={false}
                name="noExpireDate"
                label={t("no expiry date")}
              />
              {!values.noExpireDate && (
                <FormControl>
                  <FieldLabel>
                    {t("licenses expire date")}{" "}
                    <span className={styles.red}>*</span>
                  </FieldLabel>
                  <DatePicker name="licenseExpireDate" />
                  <FieldError name="licenseExpireDate" />
                </FormControl>
              )}
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

CompanyFormModal.propTypes = {
  remove: PropTypes.bool,
  add: PropTypes.bool,
};

export default CompanyFormModal;
