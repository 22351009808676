import React, { useContext, useState } from "react";
import * as Yup from "yup";
import {
  FieldLabel,
  Form,
  DropzoneInput,
  FormControl,
  FieldSelect,
  FieldError,
} from "../../components/Input";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";

import { I18nContext } from "../../i18n/I18nContext";
import styles from "./ImportDataModal.module.scss";
import ActivityIndicator from "../ActivityIndicator";
import { fetchAssets, fetchChosenAssets, importUsers } from "../../redux";
import {
  importAssets,
  importBuildings,
  exportAssetsTemplate,
  exportUsersTemplate,
} from "../../redux";
import Button from "../Button";

const basicValues = {
  prototypeIds: [],
  files: [],
};

const maxAttachmentsSize = 209715200; //200MB

const ImportDataModal = ({
  onClose,
  isOpen,
  mode,
  assetId,
  assetParentId,
  handleOpen,
}) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(basicValues);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [assetPrototypes, setAssetPrototypes] = useState([]);

  const topPrototypes = useSelector((state) => state.prototypes.top);
  const notTopPrototypes = useSelector((state) => state.prototypes.notTop);
  const allChosen = useSelector((state) => state.buildings.choosenBuildings);

  const handleClose = () => {
    onClose();
    setResult(null);
    setIsLoading(false);
  };

  const validationSchema =
    mode === "buildings" || mode === "assets"
      ? Yup.object({
          files: Yup.array()
            .required(t("please add file to import data"))
            .test({
              message: t("please add file to import data"),
              test: (arr) => arr.length > 0,
            }),
          prototypeIds: Yup.array().required(
            t("please select one of the options")
          ),
        })
      : Yup.object({
          files: Yup.array()
            .required(t("please add file to import data"))
            .test({
              message: t("please add file to import data"),
              test: (arr) => arr.length > 0,
            }),
        });

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      if (values.files && values.files.length) {
        if (mode === "users") {
          const { data } = await dispatch(importUsers(t, values));
          if (data) {
            setResult(data);
            setIsLoading(false);
          }
        } else if (mode === "buildings") {
          const { data } = await dispatch(importBuildings(t, values));
          if (data) {
            setResult(data);
            setIsLoading(false);
          }
          if (allChosen.length > 0) {
            dispatch(fetchChosenAssets(allChosen.map((el) => el.id)));
          } else {
            dispatch(fetchAssets());
          }
        } else if (mode === "assets") {
          const { data } = await dispatch(importAssets(t, values, assetId));
          if (data) {
            setResult(data);
            handleOpen(assetId);
            setIsLoading(false);
          }
          dispatch(fetchAssets(assetId));
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const downloadTemplate = (mode) => {
    if (mode === "users") {
      dispatch(exportUsersTemplate(t));
    } else {
      dispatch(exportAssetsTemplate(t, assetPrototypes));
    }
  };

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={handleClose}
      title={t("import data", "title")}
      form="import-data-form"
      importMode
      importResult={result}
      previewMode
      pending={isLoading}
    >
      {!isLoading && !result && (
        <Form
          id="import-data-form"
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ values, errors }) => {
            return (
              <>
                {(mode === "buildings" || mode === "assets") && (
                  <div className={styles.sectionWrapper}>
                    <div className={styles.template_info}>
                      {t(
                        "type selection is only required to generate the import template file - it is not needed for the import itself"
                      )}
                    </div>
                    {mode === "buildings" && (
                      <FormControl>
                        <FieldLabel>
                          {t("building prototype")}
                          <span className={styles.red}> *</span>
                        </FieldLabel>
                        <div className={styles.width}>
                          <FieldSelect
                            size="s"
                            name="prototypeIds"
                            onChange={(prototypeIds) =>
                              setAssetPrototypes(prototypeIds)
                            }
                            options={topPrototypes.map((proto) => ({
                              label: proto.name,
                              value: proto.id,
                              global: proto.visibleOnAllCompanies,
                            }))}
                            placeholder={t("building prototype")}
                            isMultiple={true}
                          />
                        </div>
                        <FieldError name="prototypeIds" />
                      </FormControl>
                    )}
                    {mode === "assets" && (
                      <FormControl>
                        <FieldLabel>
                          {t("asset prototype")}
                          <span className={styles.red}> *</span>
                        </FieldLabel>
                        <div className={styles.width}>
                          <FieldSelect
                            size="s"
                            name="prototypeIds"
                            onChange={(prototypeIds) =>
                              setAssetPrototypes(prototypeIds)
                            }
                            options={notTopPrototypes.map((proto) => ({
                              label: proto.name,
                              value: proto.id,
                              global: proto.visibleOnAllCompanies,
                            }))}
                            placeholder={t("asset prototype")}
                            isMultiple={true}
                          />
                        </div>
                        <FieldError name="prototypeIds" />
                      </FormControl>
                    )}
                    <div className={styles.margin_bottom}>
                      {(mode === "buildings" || mode === "assets") && (
                        <Button
                          className={styles.btn_spacer}
                          onClick={() => downloadTemplate(mode)}
                          type="download"
                          variant={
                            !assetPrototypes.length ? "disabled" : "orange"
                          }
                          size="s"
                          disabled={!assetPrototypes.length}
                        >
                          {t("download template for assets import")}
                        </Button>
                      )}
                    </div>
                  </div>
                )}
                {mode === "users" && (
                  <div className={styles.sectionWrapper}>
                    <Button
                      className={styles.btn_spacer}
                      onClick={() => downloadTemplate(mode)}
                      type="download"
                      variant="orange"
                      size="s"
                    >
                      {t("download template for users import")}
                    </Button>
                  </div>
                )}
                <div className={styles.sectionWrapper}>
                  <div className={styles.margin_bottom}>
                    <FieldLabel>
                      {t("select file and import data")}
                      <span className={styles.red}> *</span>
                    </FieldLabel>
                    <DropzoneInput
                      maxSize={maxAttachmentsSize}
                      name="files"
                      text={t("drag and drop a file to upload, or")}
                      fileType="excel"
                    />
                  </div>
                </div>
              </>
            );
          }}
        </Form>
      )}
      {isLoading && (
        <>
          <div className={styles.isLoading}>
            <ActivityIndicator />
          </div>
          <div className={styles.info}>
            {t(
              "you can close the window and import will continue in the background"
            )}
            .
          </div>
          <div className={styles.info2}>
            {t("do not close or refresh browser window")}.
          </div>
          <div className={styles.info2}>
            {t(
              "If you navigate away from Assets tab and then back to it before the import is completed, the page needs to be refreshed manually after the import is completed for imported assets to be displayed",
              "asIs"
            )}
            .
          </div>
          <div className={styles.info2}>
            {t(
              "if there are any errors during import the summary file will be downloaded automatically"
            )}
            .
          </div>
        </>
      )}

      {result && !isLoading && (
        <div>
          {!result.allImportFailed && result.importSuccess && (
            <div className={styles.import_info}>
              <span className={styles.info_positive}>
                {t("successfully loaded all data")}
              </span>
            </div>
          )}
          {result.allImportFailed && (
            <div className={styles.import_info}>
              <span className={styles.info_negative}>
                {t("all import failed")}
              </span>
              <a className={styles.info_file_link} href={result.file}>
                {t("download import summary")}
              </a>
            </div>
          )}
          {!result.allImportFailed && !result.importSuccess && (
            <div className={styles.import_info}>
              <span className={styles.info_negative}>
                {t("some records failed")}
              </span>
              <a className={styles.info_file_link} href={result.file}>
                {t("download import summary")}
              </a>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ImportDataModal;
