import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { accountNotActivatedError, clearAuthError, login } from "../../redux";

import { appUrls, outerUrls } from "../../urls";
import * as Yup from "yup";
import { I18nContext } from "../../i18n/I18nContext";

import {
  Checkbox,
  FieldError,
  FieldInput,
  FieldLabel,
  Form,
  FormControl,
} from "../../components/Input";
import Button from "../../components/Button";
import styles from "./LoginForm.module.scss";
import LogoIcon from "../../assets/image/Normec_logo.svg";

const LoginForm = () => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const error = useSelector((state) => state.auth.error);
  const accountValidationError = useSelector(
    (state) => state.auth.showAccountNotActivatedError
  );

  const [isPasswordShown, setPasswordShown] = useState(false);

  useEffect(() => {
    dispatch(accountNotActivatedError(false));
    dispatch(clearAuthError());
  }, []);

  const handlePasswordVisibility = () => {
    setPasswordShown(!isPasswordShown);
  };

  const initialValues = {
    email: "",
    password: "",
    rememberMe: localStorage.getItem("rm")
      ? !!JSON.parse(localStorage.getItem("rm"))
      : false,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("wrong e-mail format"))
      .required(t("e-mail needs to be provided")),
    password: Yup.string().required("Enter password."),
    //TODO unbcomment validation once set of the rules is discussed and test finished
    // .min(8, "Password must contain at least 8 characters")
    // .matches(/[a-z]/, {
    //   message: "Password must contain at least one lowercase character",
    // })
    // .matches(/[A-Z]/, {
    //   message: "Password must contain at least one uppercase character",
    // })
    // .matches(/[a-zA-Z]+[^a-zA-Z\s]+/, {
    //   message: "Password must contain at least 1 number or special character",
    // })
    // .max(100, "Password can contain up to 100 characters"),
  });

  const handleSubmit = (values) => {
    dispatch(login(t, values));
  };

  return (
    <div className={styles.container}>
      <span className={styles.header}>T@pper</span>
      <span className={styles.txt}>
        {`${t("please login to start your work")}!`}
      </span>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <FormControl>
          <FieldLabel>
            {t("e-mail")}
            <span className={styles.red}>*</span>
          </FieldLabel>
          <FieldInput
            name="email"
            type="email"
            data-testid="email"
            autoComplete="username"
          />
          <FieldError name="email" />
        </FormControl>
        <div className={styles.password_wrapper}>
          <FormControl>
            <FieldLabel>
              {t("password")}
              <span className={styles.red}>*</span>
            </FieldLabel>
            <FieldInput
              name="password"
              autoComplete="current-password"
              type={isPasswordShown ? "text" : "password"}
              data-testid="password"
            />
            <FieldError name="password" />
            <div
              className={styles.show_password}
              onClick={handlePasswordVisibility}
            >
              {isPasswordShown ? (
                <div className={styles.txt_show}>
                  {t("hide", "upper").toUpperCase()}
                </div>
              ) : (
                <div className={styles.txt_show}>
                  {t("show", "upper").toUpperCase()}
                </div>
              )}
            </div>
          </FormControl>
        </div>
        {accountValidationError && (
          <span className={styles.error_password}>
            {t(
              "account activation was not completed for specified email address"
            ) + ". "}
            {t("please check your inbox to continue the activation process")}.
          </span>
        )}
        {error && !accountValidationError && (
          <span className={styles.error_password}>
            {t("email or password incorrect")}
          </span>
        )}
        <div className={styles.reminder_container}>
          <Checkbox
            className={styles.link_forgot}
            name="rememberMe"
            label={t("remember me")}
          />
          <Link className={styles.link_forgot} to={appUrls.FORGOT_PASSWORD}>
            {`${t("forgot password")}?`}
          </Link>
        </div>
        <Button type="submit" variant="orange" size="l" role="login">
          {t("login")}
        </Button>
        <div className={styles.contact_container}>
          <span className={styles.txt_account}>
            {`${t("don’t have an account")}?`}
          </span>
          <a
            className={styles.link_account}
            href={outerUrls.CONTACT_US}
            target="_blank"
            rel="noreferrer"
          >
            {t("contact us")}
          </a>
        </div>
      </Form>
      <img className={styles.logo} src={LogoIcon} alt="" />
    </div>
  );
};
LoginForm.propTypes = {
  user: PropTypes.object,
};

export default LoginForm;
