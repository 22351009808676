import React, { useContext } from "react";
import PropTypes from "prop-types";
import { getInitialState, PrototypeForm } from "../PrototypeForm";
import Button from "../Button";
import { I18nContext } from "../../i18n/I18nContext";
import { Form } from "../Input";
import styles from "./Properties.module.scss";

const Properties = ({ assetProperties = [], onUpdate }) => {
  const { t } = useContext(I18nContext);

  return (
    <div className={styles.properties}>
      <Form
        initialValues={getInitialState(assetProperties, {})}
        onSubmit={onUpdate}
      >
        <PrototypeForm assetProperties={assetProperties.properties} />
        {!!assetProperties.properties.length && (
          <div className={styles.container}>
            <div className={styles.button}>
              <Button type="submit" variant="orange" size="s">
                {t("save")}
              </Button>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

Properties.propTypes = {
  assetProperties: PropTypes.object,
  onUpdate: PropTypes.func,
};

export default Properties;
