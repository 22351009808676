import React, { useContext, useState } from "react";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./RuleCondition.module.scss";
import Select from "react-select";

const SelectionCondition = (props) => {
  const {
    condition,
    readOnly,
    selectStyles,
    selectCondition,
    isMultiple,
    isDropdown,
    selectionOptions,
  } = props;
  const { t } = useContext(I18nContext);

  const propertyOptionsOptions = selectionOptions.map((option) => ({
    value: option,
    label: option,
  }));

  const [conditionValue, setConditionValue] = useState(
    condition && Array.isArray(condition.triggeringSelections)
      ? condition.triggeringSelections.map((selection) => ({
          value: selection,
          label: selection,
        }))
      : condition
      ? {
          value: condition.triggeringSelections,
          label: condition.triggeringSelections,
        }
      : null
  );

  return (
    <div className={styles.condition}>
      <div className={styles.property_value}>{t("property value")}</div>
      <Select
        styles={selectStyles}
        menuPosition={"relative"}
        isMulti={isMultiple}
        isClearable={false}
        isDisabled={readOnly}
        value={conditionValue}
        maxMenuHeight={200}
        onChange={(selected) => {
          setConditionValue(selected);
          if (!isMultiple) {
            selectCondition({
              value: selected,
              type: "selection_precondition",
              isMultiselect: isMultiple,
              isDropdown: isDropdown,
            });
          }
        }}
        onBlur={() => {
          if (!!isMultiple) {
            selectCondition({
              value: conditionValue,
              type: "selection_precondition",
              isMultiselect: isMultiple,
              isDropdown: isDropdown,
            });
          }
        }}
        options={propertyOptionsOptions}
        placeholder={t("select property value")}
        noOptionsMessage={() => t("no options")}
        closeMenuOnSelect={!isMultiple}
        blurInputOnSelect={false}
      />
    </div>
  );
};

export default SelectionCondition;
