import React, { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import Dashboard from "../../layouts/Dashboard";
import Task from "./Task";
import Loader from "../../components/Loader";
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
} from "../../components/Accordion";
import {
  fetchAssetPrototypes,
  fetchTopAssetPrototypes,
  fetchNotTopAssetPrototypes,
  fetchAssets,
  getAllAssets,
  fetchChosenAssets,
} from "../../redux";
import styles from "./Assets.module.scss";
import MeatballMenu from "./MeatballMenu";
import AddAssetModal from "./AddAssetModal";
import { I18nContext } from "../../i18n/I18nContext";
import Button from "../../components/Button";
import ImportDataModal from "../../components/ImportDataModal/ImportDataModal";
import AssetDetailsModal from "./AssetDetailsModal";
import useDeepCompareEffect from "use-deep-compare-effect";

const Assets = () => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [fetchedAssets, setFetchedAssets] = useState([]);
  const [openAddAssetModal, setOpenAddAssetModal] = useState();
  const [isBuilding, setIsBuilding] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [meatballOpened, setMeatballOpened] = useState(null);
  const [openAssetDetailsModal, setOpenAssetDetailsModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedAssetParent, setSelectedAssetParent] = useState(null);
  const [selectedAssetParents, setSelectedAssetParents] = useState([]);
  const [showInactiveAssets, setShowInactiveAssets] = useState(false);
  const [showOnlyAssetsWithOpenTasks, setShowOnlyAssetsWithOpenTasks] =
    useState(false);

  const assets = useSelector(getAllAssets);
  const isLoading = useSelector((state) => state.assets.loading);

  const role = useSelector((state) => state.auth.info?.role);

  const allChosen = useSelector((state) => state.buildings.choosenBuildings);

  const LICENSE_STATUSES_MESSAGES = {
    NOTSTARTED: t("licenses are not active yet"),
    EXPIRED: t("licenses expired"),
  };

  useEffect(() => {
    dispatch(fetchAssetPrototypes());
    if (!(role == "CUSTOMER" || role == "MAINTAINER")) {
      dispatch(fetchTopAssetPrototypes());
      dispatch(fetchNotTopAssetPrototypes());
    }
  }, []);

  useDeepCompareEffect(() => {
    if (allChosen.length > 0) {
      dispatch(fetchChosenAssets(allChosen.map((el) => el.id)));
    } else {
      dispatch(fetchAssets());
    }
  }, [allChosen]);

  const clearSelections = () => {
    window.location.reload();
  };

  const handleOpen = (parentId) => {
    if (fetchedAssets.includes(parentId)) {
      return;
    }
    setFetchedAssets([...fetchedAssets, parentId]);
    dispatch(fetchAssets(parentId));
  };

  const handleOpenBuilding = (parentId) => {
    if (fetchedAssets.includes(parentId)) {
      setSelectedBuilding(parentId);
      return;
    }
    setFetchedAssets([...fetchedAssets, parentId]);
    dispatch(fetchAssets(parentId));
    setSelectedBuilding(parentId);
  };

  const renderAccordion = (assets, level, parentState, parentsIds = []) => {
    const nextLevel = level + 1;
    if (!assets.subAssetsCount) {
      return null;
    }

    const accordionItemClassNames = cx({
      [styles.accordion_nested]: level > 0,
      [styles.accordion_top]: level === 0,
    });

    const accordionHeaderClassNames = cx({
      [styles.accordion_header_nested]: level > 0,
    });

    const createAssetLabel = (asset) => {
      if (asset.displayedProperties && asset.displayedProperties.length) {
        let displayedPropertiesValues = [];

        asset.displayedProperties.forEach((prop) => {
          const assetProperty = asset.assetProperties.properties.find(
            (x) => x.id === prop.id
          );

          let propertyValue = "";

          if (!!assetProperty) {
            switch (assetProperty.propertyType) {
              case "text":
                propertyValue = assetProperty.contents;
                break;
              case "date":
                propertyValue = assetProperty.selectedDate;
                break;
              case "selection":
                propertyValue = assetProperty?.chosenOptions
                  .filter((x) => !!x)
                  .join();
                break;
              case "numeric":
                const unit = assetProperty.unit
                  ? assetProperty.unit.symbol
                  : "";
                propertyValue = assetProperty.value
                  ? assetProperty.value.toString() + " " + unit
                  : "";
                break;
              default:
                propertyValue = assetProperty.state ? t("TRUE") : t("FALSE");
            }
            if (propertyValue) {
              displayedPropertiesValues.push(propertyValue);
            }
          }
        });

        return displayedPropertiesValues.join(" / ");
      } else {
        return "";
      }
    };

    return (
      <Accordion key={assets.id}>
        {assets.subAssets
          .sort((a, b) =>
            a.name.toUpperCase() < b.name.toUpperCase()
              ? -1
              : a.name.toUpperCase() > b.name.toUpperCase()
              ? 1
              : 0
          )
          .filter(
            (asset) =>
              showInactiveAssets ||
              asset.assetState === "ACTIVE" ||
              (asset.assetState === "FOR_CREATION" && role !== "MAINTAINER")
          )
          .filter(
            (asset) =>
              !showOnlyAssetsWithOpenTasks ||
              asset.tasksCount ||
              asset.childrenTaskCount
          )
          .map((asset) => (
            <Fragment key={asset.id}>
              {((!asset.alert &&
                asset.id === selectedBuilding &&
                !asset.parentId) ||
                (!asset.alert && asset.parentId) ||
                (!asset.alert && !selectedBuilding)) && (
                <AccordionItem
                  key={asset.id}
                  id={asset.id}
                  className={accordionItemClassNames}
                  parentState={parentState}
                  updateAsset={(id) => {
                    dispatch(fetchAssets(id ? id : asset.parentId));
                    if (!asset.parentId) {
                      if (allChosen.length > 0) {
                        dispatch(
                          fetchChosenAssets(allChosen.map((el) => el.id))
                        );
                      } else {
                        dispatch(fetchAssets());
                      }
                    }
                  }}
                  assetState={asset.assetState}
                  assetAlertLevel={asset.alertLevel}
                  assetIconColor={asset.color}
                  parentsIds={
                    !asset.parentId
                      ? parentsIds
                      : [...parentsIds, asset.parentId]
                  }
                >
                  <AccordionHeader
                    asset={asset}
                    text={asset.name}
                    icon={asset.icon}
                    alertLevel={asset.alertLevel}
                    assetState={asset.assetState}
                    iconColor={asset.color}
                    subtext={createAssetLabel(asset)}
                    hasParents={asset.parentId}
                    isGrouping={asset.grouping}
                    hasPlan={asset.hasPlan}
                    childHasPlan={asset.childHaveLayout}
                    onClose={() => setSelectedBuilding(null)}
                    hasItem={!!asset.subAssetsCount}
                    badge={asset.tasksCount}
                    badge2={asset.childrenTaskCount}
                    isNotActive={
                      asset.licenseStatus === "NOTSTARTED" ||
                      asset.licenseStatus === "EXPIRED"
                    }
                    activationStatus={
                      asset.assetState === "ACTIVE" ||
                      asset.assetState === "FOR_CREATION"
                    }
                    forCreation={asset.assetState === "FOR_CREATION"}
                    message={
                      asset.licenseStatus === "NOTSTARTED" ||
                      asset.licenseStatus === "EXPIRED"
                        ? LICENSE_STATUSES_MESSAGES[asset.licenseStatus]
                        : null
                    }
                    onClick={
                      asset.subAssetsCount
                        ? !asset.parentId
                          ? handleOpenBuilding
                          : handleOpen
                        : asset.grouping ||
                          asset.licenseStatus === "NOTSTARTED" ||
                          asset.licenseStatus === "EXPIRED"
                        ? null
                        : () => {
                            setSelectedAsset(asset.id);
                            setSelectedAssetParent(asset?.parentId || null);
                            setSelectedAssetParents(
                              !asset.parentId
                                ? parentsIds
                                : [...parentsIds, asset.parentId]
                            );
                            setOpenAssetDetailsModal(true);
                          }
                    }
                    level={level}
                    divider
                    className={accordionHeaderClassNames}
                    rightIcon={
                      <MeatballMenu
                        asset={asset}
                        showAdd={asset.grouping}
                        id={asset.id}
                        meatballOpened={meatballOpened}
                        meatballOpenedObserver={(state) =>
                          setMeatballOpened(state)
                        }
                        showAssetDetails={() => {
                          setSelectedAsset(asset.id);
                          setSelectedAssetParent(asset?.parentId || null);
                          setSelectedAssetParents(
                            !asset.parentId
                              ? parentsIds
                              : [...parentsIds, asset.parentId]
                          );
                          setOpenAssetDetailsModal(true);
                        }}
                        handleOpen={handleOpen}
                        refreshAssetData={() => dispatch(fetchAssets(asset.id))}
                        updateAsset={(id) => {
                          dispatch(fetchAssets(id ? id : asset.parentId));
                        }}
                        updateTopAssets={() => {
                          if (allChosen.length > 0) {
                            dispatch(
                              fetchChosenAssets(allChosen.map((el) => el.id))
                            );
                          } else {
                            dispatch(fetchAssets());
                          }
                        }}
                        parentsIds={
                          !asset.parentId
                            ? parentsIds
                            : [...parentsIds, asset.parentId]
                        }
                        onDeleteBuilding={() => setSelectedBuilding(null)}
                      />
                    }
                  >
                    {asset.address && (
                      <span>{`${asset.address.street}, ${asset.address.zipCode} ${asset.address.city}`}</span>
                    )}
                  </AccordionHeader>

                  <AccordionPanel>
                    <div className={styles.panel}>
                      {renderAccordion(
                        asset,
                        nextLevel,
                        asset.assetState,
                        !asset.parentId
                          ? parentsIds
                          : [...parentsIds, asset.parentId]
                      )}
                    </div>
                  </AccordionPanel>
                </AccordionItem>
              )}
              {asset.alert && (
                <Task
                  text={asset.name}
                  type={asset.alert}
                  alert={asset.alert}
                  level={level}
                  badge={asset.tasksCount}
                />
              )}
            </Fragment>
          ))}
      </Accordion>
    );
  };

  if (!assets) {
    return null;
  }

  return (
    <Dashboard>
      {isLoading && <Loader />}
      <div className={styles.building_top_bar}>
        <div className={styles.building_buttons}>
          {!(role == "CUSTOMER" || role == "MAINTAINER") && (
            <Button
              onClick={() => {
                setOpenAddAssetModal(true);
                setIsBuilding(true);
              }}
              variant="orange"
              size="s"
            >
              {t("add building")}
            </Button>
          )}
          {!(role == "CUSTOMER" || role == "MAINTAINER") && (
            <Button
              onClick={() => setIsImportModalOpen(true)}
              variant="orange"
              size="s"
            >
              {t("import buildings")}
            </Button>
          )}
        </div>
        <div className={styles.building_filters}>
          {!(role == "CUSTOMER" || role == "MAINTAINER") && (
            <div className={styles.building_filter}>
              <label
                className={styles.input_label_checkbox}
                htmlFor={"showInactive"}
              >
                {t("show also inactive assets")}
              </label>
              <input
                id="showInactive"
                name="showInactive"
                type="checkbox"
                className={styles.checkbox}
                onChange={(e) => setShowInactiveAssets(!showInactiveAssets)}
                checked={showInactiveAssets}
              />
            </div>
          )}
          <div className={styles.building_filter}>
            <label
              className={styles.input_label_checkbox}
              htmlFor={"showOnlyWithTasks"}
            >
              {t("show only assets with open tasks")}
            </label>
            <input
              id="showOnlyWithTasks"
              name="showOnlyWithTasks"
              type="checkbox"
              className={styles.checkbox}
              onChange={(e) =>
                setShowOnlyAssetsWithOpenTasks(!showOnlyAssetsWithOpenTasks)
              }
              checked={showOnlyAssetsWithOpenTasks}
            />
          </div>
        </div>
      </div>
      <div className={styles.assets_title}>
        <span className={styles.txt1}>{t("building name")}</span>
        <span className={styles.txt2}></span>
        <span className={styles.txt4}>{t("asset tasks")}</span>
        <span className={styles.txt4}>{t("sub-assets tasks")}</span>
        <span className={styles.txt3}>{t("actions")}</span>
      </div>
      {renderAccordion(assets, 0)}
      {!!openAddAssetModal && (
        <AddAssetModal
          assetId={null}
          isOpen={openAddAssetModal}
          onClose={() => {
            setOpenAddAssetModal(false);
            setIsBuilding(false);
          }}
          isBuilding={isBuilding}
          assets={
            assets?.subAssets.length
              ? assets.subAssets.map((el) => el.name)
              : []
          }
        />
      )}
      {!!isImportModalOpen && (
        <ImportDataModal
          isOpen={isImportModalOpen}
          onClose={() => {
            dispatch(fetchAssets());
            setIsImportModalOpen(false);
          }}
          mode="buildings"
        />
      )}
      {!!openAssetDetailsModal && (
        <AssetDetailsModal
          id={selectedAsset}
          isOpen={openAssetDetailsModal}
          onClose={() => {
            dispatch(fetchAssets(selectedAssetParent));
            setSelectedAsset(null);
            setOpenAssetDetailsModal(false);
          }}
          onDeleteBuilding={() => setSelectedBuilding(null)}
          updateAsset={(id) => {
            dispatch(fetchAssets(id));
            if (allChosen.length > 0) {
              dispatch(fetchChosenAssets(allChosen.map((el) => el.id)));
            } else {
              dispatch(fetchAssets());
            }
          }}
          parentsIds={selectedAssetParents}
        />
      )}
      {!openAssetDetailsModal && (
        <div className={styles.hiddenReloadButton} onClick={clearSelections} />
      )}
    </Dashboard>
  );
};

export default Assets;
