import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import AddAssetModal from "./AddAssetModal";
import Icon from "../../components/Icon";
import { I18nContext } from "../../i18n/I18nContext";
import EditAssetModal from "./EditAssetModal";
import DeleteAssetModal from "./DeleteAssetModal";
import styles from "./MeatballMenu.module.scss";
import ToggleActivateModal from "./ToggleActivateModal";
import history from "../../history";
import { generatePath } from "react-router-dom";
import GroupTasksModal from "../../components/Tasks/GroupTasksModal";
import ImportDataModal from "../../components/ImportDataModal/ImportDataModal";
import AttachmentsExplorerModal from "../../components/AttachmentsExplorer/AttachmentsExplorerModal";
import { Role } from "../../constants/Access";
import { useSelector } from "react-redux";

const MeatballMenu = ({
  asset,
  showAdd,
  selectedBuilding,
  id,
  meatballOpened,
  meatballOpenedObserver,
  showAssetDetails,
  handleOpen,
  refreshAssetData,
  updateAsset,
  updateTopAssets,
  parentsIds,
  onDeleteBuilding,
}) => {
  const { t } = useContext(I18nContext);
  const role = useSelector((state) => state.auth.info?.role);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const [openAddAssetModal, setOpenAddAssetModal] = useState(false);
  const [openDeleteAssetModal, setOpenDeleteAssetModal] = useState(false);
  const [openEditAssetModal, setOpenEditAssetModal] = useState(false);
  const [openToggleModal, setOpenToggleModal] = useState(false);
  const [openGroupTaskModal, setOpenGroupTaskModal] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isAttachmentsExplorerModalOpen, setIsAttachmentsExplorerModalOpen] =
    useState(false);

  useEffect(() => {
    if (meatballOpened !== id) {
      setIsActive(false);
    }
  }, [meatballOpened]);

  const onClick = (event) => {
    event.stopPropagation();
    if (meatballOpenedObserver) {
      !isActive ? meatballOpenedObserver(id) : meatballOpenedObserver(null);
    }
    setIsActive(!isActive);
  };

  const classOpenMeatball = cx(styles.menu, {
    [styles.active]: isActive,
    [styles.inactive]: !isActive,
  });

  let meatballMenuItems = [
    {
      label: t("show details"),
      show: true,
      // onClick: () => handleOpenDetails(asset.id),
      onClick: () => showAssetDetails(asset.id),
      icon: <Icon name="eye" className={styles.icon_menu} />,
    },
    {
      label: t("edit"),
      show: role !== Role.CUSTOMER && role !== Role.MAINTAINER,
      onClick: () => setOpenEditAssetModal(true),
      icon: <Icon name="edit" className={styles.icon_menu} />,
    },
    {
      label:
        asset.assetState !== "ACTIVE"
          ? t("activate asset")
          : t("deactivate asset"),
      show:
        asset.grouping &&
        asset.assetState !== "FOR_REMOVE" &&
        asset.assetState !== "REMOVED" &&
        role !== Role.CUSTOMER &&
        role !== Role.MAINTAINER,
      onClick: () => setOpenToggleModal(true),
      icon: <Icon name="padlock-black" className={styles.icon_menu} />,
    },
    {
      label: t("delete"),
      show: role !== Role.CUSTOMER && role !== Role.MAINTAINER,
      onClick: () => setOpenDeleteAssetModal(true),
      icon: <Icon name="trash" className={styles.icon_menu} />,
    },
    {
      label: t("attachments explorer"),
      show: role !== Role.CUSTOMER && role !== Role.MAINTAINER,
      onClick: () => setIsAttachmentsExplorerModalOpen(true),
      icon: <Icon name="attached-files" className={styles.icon_menu} />,
    },
    {
      label: t("add group task"),
      show: showAdd && role !== Role.CUSTOMER && role !== Role.MAINTAINER,
      onClick: () => setOpenGroupTaskModal(true),
      icon: <Icon name="add-menu" className={styles.icon_menu} />,
    },
    {
      label: t("add single sub-asset"),
      onClick: () => setOpenAddAssetModal(true),
      show: showAdd && role !== Role.CUSTOMER && role !== Role.MAINTAINER,
      icon: <Icon name="add-menu" className={styles.icon_menu} />,
    },
    {
      label: t("import sub-assets"),
      show:
        role !== Role.CUSTOMER && role !== Role.MAINTAINER && asset.grouping,
      onClick: () => setIsImportModalOpen(true),
      icon: <Icon name="add-menu" className={styles.icon_menu} />,
    },
  ];

  return (
    <>
      <div
        className={styles.menu_container}
        onClick={onClick}
        key={asset.id}
        ref={dropdownRef}
      >
        <div className={styles.menu_trigger}>
          <Icon name="meatball-menu" />
        </div>
        <div className={classOpenMeatball}>
          {meatballMenuItems
            .filter((item) => item.show)
            .map((item, idx) => {
              return (
                <div
                  className={
                    item.hasPlan !== undefined && item.hasPlan === false
                      ? styles.item_container_opacity
                      : styles.item_container
                  }
                  key={idx}
                  onClick={item.onClick}
                >
                  <span> {item.icon} </span>
                  <span className={styles.label_menu}>{item.label}</span>
                </div>
              );
            })}
        </div>
      </div>
      {!!openAddAssetModal && (
        <AddAssetModal
          parentId={asset.id}
          licenseId={asset.licenseId}
          isOpen={openAddAssetModal}
          onClose={() => setOpenAddAssetModal(false)}
        />
      )}
      {!!openEditAssetModal && (
        <EditAssetModal
          asset={asset}
          isOpen={openEditAssetModal}
          onClose={() => setOpenEditAssetModal(false)}
        />
      )}
      {!!openDeleteAssetModal && (
        <DeleteAssetModal
          isOpen={openDeleteAssetModal}
          asset={asset}
          onClose={async () => {
            setOpenDeleteAssetModal(false);
            parentsIds.forEach((parentId) => {
              updateAsset(parentId);
            });
            updateTopAssets();
            if (!asset.parentId) {
              onDeleteBuilding();
            }
          }}
        />
      )}
      {!!openToggleModal && (
        <ToggleActivateModal
          isOpen={openToggleModal}
          asset={asset}
          onClose={() => setOpenToggleModal(false)}
        />
      )}
      {!!openGroupTaskModal && (
        <GroupTasksModal
          isOpen={openGroupTaskModal}
          onClose={() => {
            refreshAssetData();
            setOpenGroupTaskModal(false);
          }}
          asset={asset}
          updateAsset={updateAsset}
          updateTopAssets={updateTopAssets}
        />
      )}
      {!!isImportModalOpen && (
        <ImportDataModal
          isOpen={isImportModalOpen}
          onClose={() => {
            refreshAssetData();
            setIsImportModalOpen(false);
          }}
          mode="assets"
          assetId={asset.id}
          handleOpen={handleOpen}
        />
      )}
      {!!isAttachmentsExplorerModalOpen && (
        <AttachmentsExplorerModal
          isOpen={isAttachmentsExplorerModalOpen}
          onClose={() => setIsAttachmentsExplorerModalOpen(false)}
          assetId={asset.id}
          assetName={asset.name}
        />
      )}
    </>
  );
};

MeatballMenu.propTypes = {
  showAdd: PropTypes.bool,
  id: PropTypes.string,
  parentId: PropTypes.string,
  licenseId: PropTypes.string,
};

export default MeatballMenu;
