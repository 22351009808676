import PropertyType from "../../constants/PropertyType";

const updateFormValue = (current, property) => {
  if (property.propertyType === PropertyType.boolean) {
    current[property.id] = property.state;
  }
  if (property.propertyType === PropertyType.text) {
    current[property.id] = property.contents;
  }
  if (property.propertyType === PropertyType.selection) {
    current[property.id] = property.multiselect
      ? property.chosenOptions
      : property.chosenOptions?.[0];
  }
  if (property.propertyType === PropertyType.date) {
    current[property.id] = property.selectedDate;
  }
  if (property.propertyType === PropertyType.numeric) {
    if (property.hasRange) {
      current[property.id] = property.value;
    } else {
      current[property.id] = property.value;
    }
  }
};

const checkIfIsObject = (value) => {
  return typeof value === "object" && !Array.isArray(value) && value !== null;
};

export const getInitialState = (assetProperties, initialState) => {
  const properties = checkIfIsObject(assetProperties)
    ? assetProperties.properties
    : assetProperties;

  if (!properties.length) {
    return initialState;
  }

  return properties.reduce(
    (current, property) => {
      let prop = !!property.property ? property.property : property;

      if (property.id) {
        prop.id = property.id;
      }

      // if (
      //   current[prop.id] ||
      //   current[`${prop.id}_lowerBound`] ||
      //   current[`${prop.id}_upperBound`]
      // ) {
      updateFormValue(current, prop);
      // }

      // if (prop.propertyType === PropertyType.composite) {
      //   Object.values(prop.elements).forEach((element) => {
      //     updateFormValue(current, element);
      //   });
      // } else {
      //   updateFormValue(current, prop);
      // }
      return current;
    },
    { ...initialState }
  );
};

export const addFormIdToFields = (fields, formId) => {
  return fields.map((field) => {
    return {
      ...field,
      name: prefixWithName(field.name, formId),
    };
  });
};

export const getAssetPropertiesFromForms = (forms) => {
  const assetProperties = [];
  forms.forEach((form) => {
    assetProperties.push(...addFormIdToFields(form.properties, form.id));
  });

  return assetProperties;
};

export const getInitialStateFromForms = (forms, initialValues) => {
  const assetProperties = getAssetPropertiesFromForms(forms);
  return getInitialState(assetProperties, initialValues);
};

export const convertToNameList = (list) => {
  return list.map((item) => {
    if (typeof item === "string") {
      return item;
    }
    return item.name;
  });
};

export const fixBackendCurmugedons = (assetProperties) => {
  return assetProperties.map((field) => {
    if (!field.tags) {
      return field;
    }
    return {
      ...field,
      tags: convertToNameList(field.tags),
    };
  });
};

const updateValue = (property, name, value) => {
  if (property.propertyType === PropertyType.boolean) {
    property.state = Array.isArray(value) ? undefined : value;
  }
  if (property.propertyType === PropertyType.text) {
    property.contents = Array.isArray(value) ? undefined : value;
  }
  if (property.propertyType === PropertyType.selection) {
    property.chosenOptions = Array.isArray(value) ? value : [value];
  }
  if (property.propertyType === PropertyType.date) {
    property.selectedDate = Array.isArray(value) ? undefined : value;
  }
  if (property.propertyType === PropertyType.numeric) {
    property.value = Array.isArray(value) ? undefined : value;
    // if (property.hasRange) {
    //   if (name === `${property.name}_lowerBound`) {
    //     property.range.lowerBound = value;
    //   }
    //   if (name === `${property.name}_upperBound`) {
    //     property.range.upperBound = value;
    //   }
    // } else {
    //   property.value = value;
    // }
  }
};

const updateProperty = (property, values, formId) => {
  if (
    !!property.property && !property.id
      ? property.property.id
      : property.id in values
  ) {
    const propName = prefixWithName(
      !!property.property ? property.property.name : property.name,
      formId
    );
    const propId =
      !!property.property && !property.id ? property.property.id : property.id;
    updateValue(
      !!property.property ? property.property : property,
      propName,
      values[propId]
    );
  }
  // if (prefixWithName(`${property.name}_lowerBound`, formId) in values) {
  //   const propName = prefixWithName(`${property.name}_lowerBound`, formId);
  //   updateValue(property, `${property.name}_lowerBound`, values[propName]);
  // }
  // if (prefixWithName(`${property.name}_upperBound`, formId) in values) {
  //   const propName = prefixWithName(`${property.name}_upperBound`, formId);
  //   updateValue(property, `${property.name}_upperBound`, values[propName]);
  // }
};

export const updateAssetProperties = (assetProperties, values, formId) => {
  if (!assetProperties) {
    throw new Error("No assetProperties to update");
  }
  const copy = fixBackendCurmugedons(
    JSON.parse(JSON.stringify(assetProperties))
  );

  return copy.map((property) => {
    if (property.elements) {
      Object.values(property.elements).forEach((property) => {
        updateProperty(property, values, formId);
      });
    } else {
      updateProperty(property, values, formId);
    }

    return property;
  });
};

export const updateProperties = (assetProperties, values, formId) => {
  if (!assetProperties) {
    throw new Error("No assetProperties to update");
  }

  return assetProperties.map((element) => {
    const { property } = element;
    if (property.elements) {
      Object.values(property.elements).forEach((property) => {
        updateProperty(property, values, formId);
      });
    } else {
      updateProperty(property, values, formId);
    }

    return {
      ...element,
      property,
    };
  });
};

export const updateFormProperties = (forms, values) => {
  return forms.map((form) => {
    return {
      ...form,
      properties: updateAssetProperties(form.properties, values, form.id),
    };
  });
};

export const createSelectionProperty = (propertyType, values) => {
  return {
    propertyType: "selection",
    multiselect: propertyType === "selection-multi",
    dropdown: propertyType === "selection-dropdown",
    name: values.name,
    description: "some description",
    chosenOptions: values.chosenOptions ?? [],
    options: values.options,
    flags: {
      isConfigurable: true,
      isMandatory: values.isMandatory,
      isUpdatable: values.isUpdatable,
    },
    isActive: true,
  };
};

export const createBooleanProperty = (propertyType, values) => {
  return {
    propertyType: "boolean",
    state: false,
    name: values.name,
    description: "some description",
    flags: {
      isConfigurable: true,
      isMandatory: values.isMandatory,
      isUpdatable: true,
    },
    isActive: true,
  };
};

export const createNumericProperty = (propertyType, values, units) => {
  const choosenUnit = units.find((el) => el.id === values.unit);
  return {
    propertyType: PropertyType.numeric,
    name: values.name,
    value: values.selected,
    hasRange: propertyType === "numeric-range",
    range:
      propertyType === "numeric-range"
        ? {
            step: 1,
            lowerBound: values.lowerBound,
            upperBound: values.upperBound,
          }
        : undefined,
    description: "some description",
    flags: {
      isConfigurable: true,
      isMandatory: values.isMandatory,
      isUpdatable: values.isUpdatable,
    },
    unit: choosenUnit,
    isActive: true,
  };
};

export const createOtherProperty = (propertyType, values) => {
  return {
    propertyType: propertyType,
    name: values.name,
    description: "some description",
    flags: {
      isConfigurable: true,
      isMandatory: values.isMandatory,
      isUpdatable: values.isUpdatable,
    },
    isActive: true,
  };
};

export const createCompositeProperty = (values, sectionName, units) => {
  return {
    propertyType: PropertyType.composite,
    name: prefixWithName(values.name, sectionName),
    description: "some description",
    elements: getCompositeElementsObject(
      values.compositeFields,
      values.name,
      sectionName,
      units
    ),
    flags: {
      isConfigurable: true,
      isMandatory: values.isMandatory,
      isUpdatable: true,
    },
  };
};

const getCompositeElementsObject = (
  values,
  compositePropertyName,
  sectionName,
  units
) => {
  const propertyObj = {};
  const uniqueKeys = values.map((el) => el.uniqueKey);
  const parsedProperties = values.map((el) =>
    getProperPropertyObject(
      {
        ...el,
        name: sectionName
          ? `${sectionName}__${compositePropertyName}__${el.name}`
          : `${compositePropertyName}__${el.name}`,
      },
      units
    )
  );

  parsedProperties.forEach((el, index) => {
    propertyObj[uniqueKeys[index]] = el;
  });

  return propertyObj;
};

const getProperPropertyObject = (property, units) => {
  if (property.propertyType.startsWith(PropertyType.selection)) {
    return createSelectionProperty(property.propertyType, property);
  } else if (property.propertyType.startsWith(PropertyType.numeric)) {
    return createNumericProperty(property.propertyType, property, units);
  } else {
    return createOtherProperty(property.propertyType, property);
  }
};

export const prefixWithName = (propertyName, prefix) => {
  return prefix ? `${prefix}__${propertyName}` : propertyName;
};
