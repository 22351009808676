import React, { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./LayoutViewer.module.scss";
import Icon from "../Icon";
import CanvasFacade from "./Viewer";
import Button from "../Button/Button";
import {
  toLayoutConfig,
  viewerConfig,
  toLayout,
  getUnsetPois,
  getFilterOptions,
} from "./LayoutUtils";
import axios from "../../redux/auth";
import Loader from "../Loader";
import { I18nContext } from "../../i18n/I18nContext";
import {
  deleteAssetLayout,
  fetchAssetDetails,
  selectAssetLayout,
} from "../../redux";
import DeleteLayoutModal from "./DeleteLayoutModal";
import LayoutFilter from "./LayoutFilter";

const LayoutViewer = ({ assetId, onClose, disablePlan }) => {
  const { t } = useContext(I18nContext);
  const viewer = useRef();
  const dispatch = useDispatch();
  const [layout, setLayout] = useState();
  const [unsetPois, setUnsetPois] = useState();
  const [pending, setPending] = useState(true);
  const [zoom, setZoom] = useState(1);
  const [mode, setMode] = useState("VIEWER");
  const [openDeleteAssetModal, setOpenDeleteAssetModal] = useState(false);
  const [filtersOptions, setFiltersOptions] = useState({
    assetType: [],
    taskType: [],
    alert: [],
  });
  const [filters, setFilters] = useState({
    assetType: null,
    taskType: null,
    alert: null,
  });

  const details = useSelector((state) => state.assets.details);
  const assetPrototypes = useSelector((state) => state.prototypes.items);

  useEffect(() => {
    dispatch(fetchAssetDetails(assetId));
  }, [assetId, dispatch]);

  useEffect(() => {
    viewer.current = new CanvasFacade(viewerConfig);
    viewer.current.mount(document.querySelector("#canvas"));
    getLayout();
    return () => viewer.current.unmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const getLayout = async () => {
    setPending(true);
    const { data } = await axios.get(`/assets/${assetId}/layout`);
    let urls = await getUrlsForKeys([data.key]);
    data.layoutUrl = urls[0];
    setLayout(data);
    setFiltersOptions(getFilterOptions(data.pois, assetPrototypes));
    dispatch(selectAssetLayout(data));

    //APPLYING FILTERS
    let filteredData = { ...data };
    let filteredPois = [...filteredData.pois];
    if (filters.assetType) {
      filteredPois = filteredPois.filter(
        (poi) => poi.assetPrototypeId === filters.assetType
      );
    }
    if (filters.taskType) {
      filteredPois = filteredPois.filter((poi) =>
        poi.tasksPrototypes.map((proto) => proto.id).includes(filters.taskType)
      );
    }
    if (filters.alert) {
      filteredPois = filteredPois.filter(
        (poi) => poi.alertLevel === filters.alert
      );
    }
    filteredData.pois = filteredPois;

    await viewer.current.loadLayout(toLayoutConfig(filteredData));
    setUnsetPois(getUnsetPois(filteredData));
    setPending(false);
  };

  const saveLayout = async () => {
    setPending(true);
    const newLayout = toLayout(layout, viewer.current.getPois());
    const { data } = await axios.put(
      `/assets/${layout.assetId}/layout`,
      newLayout
    );
    data.layoutUrl = layout.layoutUrl;
    setLayout(data);
    setMode(viewer.current.setMode("VIEWER"));
    setPending(false);
  };

  const deleteLayout = async () => {
    setPending(true);
    await dispatch(deleteAssetLayout(t, layout.assetId));
    setPending(false);
    disablePlan();
    onClose();
  };

  const getUrlsForKeys = async (keys) => {
    const { data } = await axios.post("/files", keys);
    return data;
  };

  const addAssetToLayout = async (poi, index) => {
    await viewer.current.addPoi(poi);
    let newUnsetPois = [...unsetPois];
    newUnsetPois.splice(index, 1);
    setUnsetPois(newUnsetPois);
  };

  const applyFilter = (value, type) => {
    let newFilters = { ...filters };
    newFilters[type] = value;
    setFilters(newFilters);
  };

  const path = !details?.path
    ? ""
    : !details?.path.length || details?.path.length === 1
    ? details.name
    : details?.path.join(" -> ") + " -> " + details.name;

  return (
    <div
      className={styles.viewerContainer}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {pending && <Loader />}
      <div className={styles.viewerScreen} id="canvas" />
      <div className={styles.menuContainer}>
        <div className={styles.path}>{path}</div>
        {!!layout?.pois.length && mode === "VIEWER" && (
          <div
            className={styles.editIconBorder}
            onClick={() => setMode(viewer.current.setMode("EDITOR"))}
          >
            <Icon name="editPencil-orange" className={styles.editIcon} />
          </div>
        )}
        {!layout?.pois.length && mode === "VIEWER" && (
          <div className={styles.editIconBorderDisabled}>
            <Icon name="editPencil-grey" className={styles.editIcon} />
            <div className={styles.disabledEditTooltip}>
              {t("no non-grouping sub-assets available to place on layout")}
            </div>
          </div>
        )}
        {mode === "VIEWER" && (
          <div
            className={styles.editIconBorder}
            onClick={() => setOpenDeleteAssetModal(true)}
          >
            <Icon name="trash" className={styles.editIcon} />
          </div>
        )}
        {mode === "VIEWER" && (
          <div className={styles.closeIconBorder} onClick={onClose}>
            <Icon name="close" onClick={onClose} />
          </div>
        )}
      </div>
      <div className={styles.toolbarContainer}>
        {mode === "EDITOR" && (
          <Button
            className={styles.button}
            variant="ghost"
            onClick={() => {
              getLayout();
              setMode(viewer.current.setMode("VIEWER"));
            }}
          >
            {t("cancel")}
          </Button>
        )}
        <div className={styles.zoomContainer}>
          <Icon
            name="loupe-minus"
            onClick={() => setZoom(viewer.current.zoomOut())}
          />
          <Icon
            name="loupe-plus"
            onClick={() => setZoom(viewer.current.zoomIn())}
          />
          <span className={styles.zoomValue}>{`${Math.round(
            zoom * 100
          )} %`}</span>
        </div>
        {mode === "EDITOR" && (
          <Button
            className={styles.button}
            variant="orange"
            onClick={saveLayout}
          >
            {t("save")}
          </Button>
        )}
      </div>
      <div className={styles.filtersContainer}>
        <div className={styles.filterLabel}>{t("filters")}</div>
        <div className={styles.filterItem}>
          <LayoutFilter
            type="assetType"
            options={filtersOptions["assetType"]}
            onSelect={applyFilter}
            placeholder={t("asset prototype")}
            value={filters["assetType"]}
            disabled={mode === "EDITOR"}
          />
        </div>
        <div className={styles.filterItem}>
          <LayoutFilter
            type="taskType"
            options={filtersOptions["taskType"]}
            onSelect={applyFilter}
            placeholder={t("task template")}
            value={filters["taskType"]}
            disabled={mode === "EDITOR"}
          />
        </div>
        <div className={styles.filterItem}>
          <LayoutFilter
            type="alert"
            options={filtersOptions["alert"]}
            onSelect={applyFilter}
            placeholder={t("alert level")}
            value={filters["alert"]}
            disabled={mode === "EDITOR"}
          />
        </div>
      </div>
      {mode === "EDITOR" && !!unsetPois.length && (
        <div className={styles.unsetPoisContainer}>
          <div className={styles.unsetPoisLabel}>
            {t("add asset to layout")}
          </div>
          {unsetPois.map((poi, index) => (
            <div
              className={styles.unsetPoiItem}
              key={poi.id}
              onClick={() => addAssetToLayout(poi, index)}
            >
              {poi.name}
            </div>
          ))}
        </div>
      )}
      <DeleteLayoutModal
        isOpen={openDeleteAssetModal}
        asset={layout}
        action={deleteLayout}
        onClose={() => setOpenDeleteAssetModal(false)}
      />
    </div>
  );
};

export default LayoutViewer;
