import Actions from "../actions";
import axios from "../auth";
import history from "../../history";
import { appUrls } from "../../urls";

const fetchRegionsSuccess = (regions) => {
  return {
    type: Actions.FETCH_REGIONS_SUCCESS,
    payload: regions,
  };
};

const selectRegionSuccess = (data) => {
  return {
    type: Actions.SELECT_REGION_SUCCESS,
    payload: data,
  };
};

const selectRegionFailure = (error) => {
  return {
    type: Actions.SELECT_REGION_FAILURE,
    payload: error,
  };
};

const fetchRegionsFailure = (error) => {
  return {
    type: Actions.FETCH_REGIONS_FAILURE,
    payload: error,
  };
};

export const fetchRegions = () => {
  return (dispatch) => {
    return axios
      .get(`/regions`)
      .then((response) => {
        dispatch(fetchRegionsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchRegionsFailure(error));
      });
  };
};

export const selectRegion = (regionId) => {
  return (dispatch) => {
    return axios
      .post(`/regionAuth`, {
        regionId,
        rememberMe: !!JSON.parse(localStorage.getItem("rm")),
      })
      .then(({ data }) => {
        dispatch(selectRegionSuccess(data));
        history.push(appUrls.DASHBOARD);
      })
      .catch((error) => {
        dispatch(selectRegionFailure(error));
        history.push(appUrls.LOGIN);
      });
  };
};
