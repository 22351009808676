import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanies, selectCompany } from "../../redux";
import { getCompaniesName } from "../../redux";
import {
  FieldError,
  FieldLabel,
  FieldSelect,
  Form,
} from "../../components/Input";
import Button from "../../components/Button";
import Entry from "../../layouts/Entry";
import styles from "./CompanySelect.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const defaultValues = {
  companyId: "",
};

const CompanySelect = () => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [initialValues] = useState(defaultValues);
  const companies = useSelector(getCompaniesName);

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (companies.length === 1) {
      handleSubmit({ companyId: companies[0].value });
    }
  }, [companies]);

  const validationSchema = Yup.object({
    companyId: Yup.mixed()
      .required(t("please choose one option"))
      .oneOf(companies.map((item) => item.value)),
  });

  const handleSubmit = (values) => {
    dispatch(
      selectCompany(
        values.companyId,
        companies.find((c) => c.value === values.companyId).label
      )
    );
  };

  if (!companies || companies.length < 2) return null;
  return (
    <Entry>
      <div className={styles.container}>
        <span className={styles.header}>{t("company")}</span>
        <span className={styles.txt_container}>
          {t("please choose company you want to work with")}
        </span>
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <FieldLabel>
            {t("company")}
            <span className={styles.red}>*</span>
          </FieldLabel>
          <FieldSelect
            name="companyId"
            options={companies.sort((a, b) => a.label.localeCompare(b.label))}
            placeholder={t("choose company")}
          />
          <FieldError name="companyId" />
          <Button
            className={styles.spacing}
            type="submit"
            variant="orange"
            size="l"
          >
            {t("next")}
          </Button>
        </Form>
      </div>
    </Entry>
  );
};
export default CompanySelect;
