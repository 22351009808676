import React, { useCallback } from "react";
import { Field } from "formik";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./FieldInput.module.scss";
import Icon from "../Icon";

const CustomInputComponent = (propsComp) => {
  const {
    field,
    form: { errors, setFieldTouched },
    icon,
    onChange,
    onBlur,
    ...props
  } = propsComp;

  const isInvalid = errors[field.name];

  const classNamesInput = cx({
    [styles.input_NoIcon]: !icon,
    [styles.input]: icon,
    [styles.error]: isInvalid,
    [styles.l]: props.size === "l",
    [styles.m]: props.size === "m",
    [styles.s]: props.size === "s",
    [styles.xs]: props.size === "xs",
    [styles.xxs]: props.size === "xxs",
    [styles.disabled]: props.disabled,
  });

  const handleChange = useCallback(
    (e) => {
      if (onChange) {
        onChange(e.target.value);
      }
      field.onChange(e);
    },
    [onChange, field]
  );

  if (!icon) {
    return (
      <input
        className={classNamesInput}
        {...field}
        {...props}
        onChange={handleChange}
        onBlur={
          !!onBlur
            ? (e) => {
                setFieldTouched(field.name, true);
                onBlur(e.target.value);
              }
            : () => {
                setFieldTouched(field.name, true);
              }
        }
      />
    );
  } else {
    return (
      <div className={styles.main_container}>
        <input
          className={classNamesInput}
          {...field}
          {...props}
          onChange={handleChange}
          onBlur={
            !!onBlur
              ? (e) => {
                  setFieldTouched(field.name, true);
                  onBlur(e.target.value);
                }
              : () => {
                  setFieldTouched(field.name, true);
                }
          }
        />
        <Icon name="loupe" className={styles.input_icon} />
      </div>
    );
  }
};

const FieldInput = ({ name, type = "text", disabled, onBlur, ...rest }) => {
  return (
    <Field
      name={name}
      type={type}
      disabled={disabled}
      component={CustomInputComponent}
      onBlur={onBlur}
      {...rest}
    />
  );
};

FieldInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(["password", "email", "text", "number"]),
  size: PropTypes.oneOf(["s", "xs", "xxs", "m", "l"]),
  icon: PropTypes.bool,
};

export default FieldInput;
