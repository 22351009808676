import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import history from "./history";
import { appUrls } from "./urls";
import Loader from "./components/Loader";
import { loginSuccess, checkIfLoggedIn } from "./redux/auth/authActions";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const isLogged = useSelector((state) => state.auth.isLogged);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const check = async () => {
    if (isLogged || sessionStorage.getItem("tapperSession")) {
      setIsLoggedIn(true);
      return;
    }
    try {
      const { data } = await checkIfLoggedIn();
      dispatch(loginSuccess(data));
      setIsLoggedIn(true);
      //TODO
    } catch (e) {
      let pathname = history.location.pathname;
      history.push(appUrls.LOGIN, {
        redirect: pathname !== "/" ? pathname : "/dashboard",
      });
    }
  };

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoggedIn) return <Loader />;
  return <Route {...rest} component={Component} />;
};

export default PrivateRoute;
