import React, { useEffect, useState, useRef, useContext } from "react";
import * as Yup from "yup";
import {
  FieldLabel,
  Form,
  FormControl,
  FieldError,
  FieldInput,
  FieldSelect,
  FieldTextArea,
  DropzoneInput,
} from "../Input";
import { useDispatch, useSelector } from "react-redux";
import {
  PrototypeForm,
  addFormIdToFields,
  getInitialStateFromForms,
  updateFormProperties,
  convertToNameList,
} from "../PrototypeForm";
import {
  addTaskPrototype,
  addTaskPrototypeAttachment,
  fetchTasksPrototypesList,
  fetchForms,
  updateTaskPrototype,
  deleteTaskPrototypeAttachment,
  taskPrototypesEditErrorClear,
} from "../../redux";
import InputTagForm from "../InputTag";
import styles from "./TaskPrototypeForm.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import { Role } from "../../constants/Access";
import SortableSelect from "../Input/SortableSelect";
import RulesEditor from "../RulesEditor/RulesEditor";
import FormEditErrorModal from "./FormEditErrorModal";

function arrayMove(array, from, to) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

const defaultInitialValues = {
  taskPrototypeNameUnique: "",
  taskPrototypeDescUnique: "",
  recurringInterval: null,
  intervalUnit: "",
  attachments: [],
  assetAttachments: [],
  tags: [],
};

const createValidationScheme = (
  t,
  properties,
  prototypes,
  recurranceRequired,
  taskPrototype,
  createPrototype,
  propertiesDisabled
) => {
  const emptyStringToNull = (value, originalValue) => {
    if (typeof originalValue === "string" && originalValue === "") {
      return null;
    }
    return value;
  };

  const createPropertySchema = () => {
    let schema = {};

    const booleanProperties = properties.filter(
      (property) =>
        property.propertyType === "boolean" && !!property.flags.isMandatory
    );
    booleanProperties.forEach((property) => {
      schema = {
        ...schema,
        [property.id]: Yup.boolean().required(t("required field")),
      };
    });

    const textProperties = properties.filter(
      (property) =>
        property.propertyType === "text" && !!property.flags.isMandatory
    );
    textProperties.forEach((property) => {
      schema = {
        ...schema,
        [property.id]: Yup.string().required(t("required field")),
      };
    });

    const singleSelectionProperties = properties.filter(
      (property) =>
        property.propertyType === "selection" &&
        !property.multiselect &&
        !!property.flags.isMandatory
    );
    singleSelectionProperties.forEach((property) => {
      schema = {
        ...schema,
        [property.id]: Yup.string().required(t("required field")),
      };
    });

    const multiSelectionProperties = properties.filter(
      (property) =>
        property.propertyType === "selection" &&
        !!property.multiselect &&
        !!property.flags.isMandatory
    );
    multiSelectionProperties.forEach((property) => {
      schema = {
        ...schema,
        [property.id]: Yup.array().required(t("required field")),
      };
    });

    const dateProperties = properties.filter(
      (property) =>
        property.propertyType === "date" && !!property.flags.isMandatory
    );
    dateProperties.forEach((property) => {
      schema = {
        ...schema,
        [property.id]: Yup.string().required(t("required field")),
      };
    });

    const numericProperties = properties.filter(
      (property) =>
        property.propertyType === "numeric" && !!property.flags.isMandatory
    );
    numericProperties.forEach((property) => {
      schema = {
        ...schema,
        [property.id]: Yup.number()
          .required(t("required field"))
          .min(
            property.unit.range.lowerBound,
            t("value cannot be out of unit range")
          )
          .max(
            property.unit.range.upperBound,
            t("value cannot be out of unit range")
          ),
      };
    });

    const rangeProperties = properties.filter(
      (property) =>
        property.propertyType === "numeric" &&
        !property.flags.isMandatory &&
        !!property.unit
    );
    rangeProperties.forEach((property) => {
      schema = {
        ...schema,
        [property.id]: Yup.number()
          .min(
            property.unit.range.lowerBound,
            t("value cannot be out of unit range")
          )
          .max(
            property.unit.range.upperBound,
            t("value cannot be out of unit range")
          ),
      };
    });

    return schema;
  };

  const propertiesSchema = propertiesDisabled ? {} : createPropertySchema();

  const recurrenceProperties = recurranceRequired
    ? {
        recurringInterval: Yup.number()
          .positive(t("number must be higher than 0"))
          .required("required"),
        intervalUnit: Yup.mixed()
          .required(t("please choose one option"))
          .oneOf(dateOptions(t).map((item) => item.value)),
      }
    : {
        recurringInterval: Yup.number()
          .positive(t("number must be higher than 0"))
          .transform(emptyStringToNull)
          .nullable(true),
        intervalUnit: Yup.mixed()
          .oneOf(dateOptions(t).map((item) => item.value))
          .nullable(true),
      };

  const taskPrototypeName = taskPrototype
    ? {
        taskPrototypeNameUnique: Yup.mixed()
          .notOneOf(
            prototypes
              .filter((t) => t.id !== taskPrototype.id && !t.deprecated)
              .map((t) => t.name),
            t("name must be unique")
          )
          .required(t("please enter the name of the task template")),
      }
    : {
        taskPrototypeNameUnique: Yup.mixed()
          .notOneOf(
            prototypes.filter((t) => !t.deprecated).map((t) => t.name),
            t("name must be unique")
          )
          .required(t("please enter the name of the task template")),
      };

  return Yup.object({
    taskPrototypeDescUnique: Yup.string().required(
      t("please provide your task description")
    ),
    ...(createPrototype && taskPrototypeName),
    ...propertiesSchema,
    ...recurrenceProperties,
    // ...propertiesSchema2,
  });
};

const isEqual = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

const dateOptions = (t) => [
  { value: "DAYS", label: t("days") },
  {
    value: "WEEKS",
    label: t("weeks"),
  },
  {
    value: "MONTHS",
    label: t("months"),
  },
  { value: "QUARTER_YEAR", label: t("quarter year") },
  { value: "HALF_YEAR", label: t("half year") },
  {
    value: "YEARS",
    label: t("years"),
  },
];

const createProperFieldsObject = (taskPrototype) => {
  let properties = [];

  taskPrototype.forms.forEach((form) => {
    const copy = [...form.properties];
    copy.forEach((field) => (field["formId"] = form.id));
    properties = [...properties, ...copy];
  });

  return properties;
};

const TaskPrototypeForm = ({
  onClose,
  disabled,
  formId = "task-prototype-form",
  taskPrototype,
  onErrors,
  onEdit,
  recurranceRequired,
  showSelectForms,
  allowEditingTags,
  allowDeletingProperties,
  allowEditingAttachments = false,
  readOnly,
  onChangeDesc,
  preview,
  createPrototype,
  viewFiles,
  noRulesEditor,
  previewMode,
  assetAttachments,
  noTaskTypeName,
  addTask,
  toggleRulesEditorOpen,
  taskPreview,
}) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const currentValues = useRef(defaultInitialValues);
  const currentErrors = useRef(null);
  const [selectedForms, setSelectedForms] = useState([]);
  const [deprecatedForms, setDeprecatedForms] = useState([]);
  const [chosenForms, setChosenForms] = useState([]);
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [showTaskTypeEditErrorModal, setShowTaskTypeEditErrorModal] =
    useState(false);

  const userRole = useSelector((state) => state.auth.info.role);
  const companyId = useSelector((state) => state.auth.info.companyId);
  const prototypes = useSelector((state) => state.taskPrototypes.items);
  const forms = useSelector((state) => state.forms.items);
  const taskTypeEditError = useSelector(
    (state) => state.taskPrototypes.taskTypeEditError
  );
  const taskTypeEditErrorStatus = useSelector(
    (state) => state.taskPrototypes.taskTypeEditErrorStatus
  );

  useEffect(() => {
    dispatch(taskPrototypesEditErrorClear());
  }, []);

  useEffect(() => {
    if (taskTypeEditErrorStatus === 400) {
      setShowTaskTypeEditErrorModal(true);
    } else {
      dispatch(taskPrototypesEditErrorClear());
    }
  }, [taskTypeEditError]);

  const properties = taskPrototype
    ? createProperFieldsObject(taskPrototype)
    : [];

  const validationSchema = createValidationScheme(
    t,
    properties,
    prototypes,
    recurranceRequired,
    taskPrototype,
    createPrototype,
    disabled
  );

  const maxAttachmentsSize = 209715200; //200MB

  // function for sortable select
  const onChange = (selectedOptions) => {
    const ids = selectedOptions.map((el) => el.value);
    const filteredForms = forms.filter((el) => ids.includes(el.id));
    setChosenForms(filteredForms);
    setSelectedForms(selectedOptions);
  };
  // function for sortable select
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selectedForms, oldIndex, newIndex);
    setSelectedForms(newValue);
  };

  const handleSubmit = async (values, formik) => {
    let taskPrototypeUpdate = taskPrototype;
    let taskPrototypeCreating = false;

    if (!taskPrototypeUpdate) {
      taskPrototypeCreating = true;
      taskPrototypeUpdate = {
        formName: "string",
        isGlobal: userRole === Role.SUPER_ADMIN,
        companyId,
        desc: values.taskPrototypeDescUnique,
        name: values.taskPrototypeNameUnique,
        formsIds: chosenForms.map((chosenf) => chosenf.id),
        tags: convertToNameList(values.tags),
      };
    }
    if (!taskPrototypeCreating) {
      taskPrototypeUpdate = {
        ...taskPrototypeUpdate,
        tags: values.tags,
        desc: values.taskPrototypeDescUnique,
        name: values.taskPrototypeNameUnique,
        formsIds: chosenForms.map((chosenf) => chosenf.id),
      };
    }

    if (values.intervalUnit && values.recurringInterval) {
      taskPrototypeUpdate.recurrence = {
        intervalUnit: values.intervalUnit,
        recurringInterval: values.recurringInterval,
        strict: true,
      };
    }

    if (!values.intervalUnit && !values.recurringInterval) {
      delete taskPrototypeUpdate.recurrence;
    }

    const { data } = taskPrototypeCreating
      ? await dispatch(addTaskPrototype(t, taskPrototypeUpdate))
      : await dispatch(updateTaskPrototype(t, taskPrototypeUpdate));

    const newAttachments = values.attachments.filter((file) => !file.key);
    if (newAttachments.length) {
      await dispatch(addTaskPrototypeAttachment(data.id, newAttachments));
    }

    await dispatch(fetchTasksPrototypesList());
    formik.resetForm();
    handleClose();
  };

  const handleClose = () => {
    setInitialValues(defaultInitialValues);
    setChosenForms([]);
    onClose();
  };

  useEffect(() => {
    const ids = selectedForms.map((el) => el.value);
    let filteredForms = [];
    let allForms = [...forms];
    if (taskPrototype) {
      allForms = [
        ...taskPrototype.forms.filter((el) => el.deprecated),
        ...allForms,
      ];
    }
    if (!preview) {
      ids.forEach((item) => {
        // const findElem = forms.find((el) => el.id === item);
        const findElem = allForms.find((el) => el.id === item);
        if (findElem) {
          filteredForms.push(findElem);
        }
      });
      setChosenForms(filteredForms);
    }
  }, [selectedForms]);

  useEffect(() => {
    if (taskPrototype) {
      const deprecated = taskPrototype.forms
        .filter((el) => el.deprecated)
        .map((el) => ({
          value: el.id,
          label: el.name,
        }));
      const selected = taskPrototype.forms.map((el) => ({
        value: el.id,
        label: el.name,
      }));
      const {
        name: taskPrototypeNameUnique,
        desc: taskPrototypeDescUnique,
        attachments,
        tags,
        recurrence: { recurringInterval, intervalUnit } = {},
      } = taskPrototype;

      currentValues.current = {
        taskPrototypeNameUnique,
        taskPrototypeDescUnique,
        recurringInterval,
        intervalUnit,
        attachments: attachments,
        tags: tags.map((tag) => tag.name),
      };
      setSelectedForms(selected);
      setDeprecatedForms(deprecated);
      setChosenForms(taskPrototype.forms);
      setInitialValues({
        taskPrototypeNameUnique,
        taskPrototypeDescUnique,
        recurringInterval,
        intervalUnit,
        tags: tags.map((tag) => tag.name),
        ...getInitialStateFromForms(taskPrototype.forms, currentValues.current),
      });
    }
  }, [taskPrototype]);

  useEffect(() => {
    if (assetAttachments) {
      currentValues.current.assetAttachments = assetAttachments;
    }
  }, [assetAttachments]);

  useEffect(() => {
    if (forms.length === 0) {
      dispatch(fetchForms());
    }
  }, []);

  useEffect(() => {
    if (taskPrototype && onEdit) {
      onEdit({
        ...taskPrototype,
        forms: updateFormProperties(chosenForms, currentValues.current),
      });
    }
  }, [chosenForms, taskPrototype, onEdit]);

  const handleDeleteProperty = (formId, propertyToRemove) => {
    setChosenForms(
      chosenForms.map((form) => {
        if (form.id === formId) {
          return {
            ...form,
            properties: form.properties.filter(
              (property) =>
                `${formId}__${property.name}` !== propertyToRemove.name
            ),
          };
        } else {
          return form;
        }
      })
    );
  };

  const handleEdit = (values) => {
    onEdit({
      ...taskPrototype,
      attachments: values.attachments,
      forms: updateFormProperties(chosenForms, values),
    });
  };

  const handleDelete = async (file) => {
    if (taskPrototype && file.key) {
      await dispatch(deleteTaskPrototypeAttachment(taskPrototype.id, file.key));
    }
  };

  return (
    <>
      <Form
        id={formId}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, errors, setFieldTouched }) => {
          if (!isEqual(currentErrors.current, errors)) {
            currentErrors.current = errors;
            if (taskPrototype && onErrors) {
              onErrors(errors);
            }
          }
          if (!isEqual(currentValues.current, values)) {
            currentValues.current = values;
            if (taskPrototype && onEdit) {
              Object.keys(values)
                .filter((value) => value.includes("Required"))
                .forEach((value) => setFieldTouched(value, true));
              handleEdit(values);
            }
          }

          return (
            <>
              {!noTaskTypeName && !taskPreview && (
                <FormControl>
                  <FieldLabel>
                    {t("name")}
                    {!readOnly && <span className={styles.red}> *</span>}
                  </FieldLabel>
                  <FieldInput
                    name="taskPrototypeNameUnique"
                    type="text"
                    disabled={readOnly}
                    placeholder={t("add template name")}
                    size="l"
                  />
                  <FieldError name="taskPrototypeNameUnique" />
                </FormControl>
              )}
              <FormControl>
                {!taskPreview && (
                  <FieldLabel>
                    {t("description")}
                    {!readOnly && <span className={styles.red}> *</span>}
                  </FieldLabel>
                )}
                <FieldTextArea
                  disabled={!onChangeDesc ? readOnly : false}
                  name="taskPrototypeDescUnique"
                  onChange={
                    onChangeDesc ? (value) => onChangeDesc(value) : null
                  }
                />
                <FieldError name="taskPrototypeDescUnique" />
              </FormControl>
              {!addTask && !taskPreview && (
                <>
                  <FieldLabel>{t("tags")}</FieldLabel>
                  <InputTagForm name="tags" disabled={!allowEditingTags} />
                </>
              )}
              {(!addTask || (!!addTask && !!recurranceRequired)) &&
                !taskPreview && (
                  <>
                    <div className={styles.row}>
                      <FormControl>
                        <FieldLabel>
                          {t("repeat task every")}
                          {recurranceRequired && (
                            <span className={styles.red}> *</span>
                          )}
                        </FieldLabel>
                        <FieldInput
                          name="recurringInterval"
                          size="xxs"
                          type="number"
                          disabled={readOnly}
                          placeholder={t("value")}
                        />
                        <FieldError name="recurringInterval" />
                      </FormControl>
                      <FormControl className={styles.width_dropdown2}>
                        <FieldLabel />
                        <FieldSelect
                          name="intervalUnit"
                          size="s"
                          options={dateOptions(t)}
                          disabled={readOnly}
                          placeholder={t("choose an option")}
                          isClearable={true}
                        />
                        <FieldError name="intervalUnit" />
                      </FormControl>
                    </div>
                  </>
                )}
              {!addTask && (
                <>
                  <FormControl classNameCustom={styles.dropzone_container}>
                    <DropzoneInput
                      disabled={!allowEditingAttachments}
                      name="attachments"
                      maxSize={maxAttachmentsSize}
                      onDelete={handleDelete}
                      view={viewFiles}
                    />
                  </FormControl>
                  <FormControl classNameCustom={styles.dropzone_container}>
                    <DropzoneInput
                      disabled={true}
                      name="assetAttachments"
                      maxSize={maxAttachmentsSize}
                      onDelete={handleDelete}
                      view={viewFiles}
                      filesToDisplay={assetAttachments}
                    />
                  </FormControl>
                </>
              )}
              {!addTask &&
                chosenForms?.map((form) => {
                  return (
                    <div key={form.id}>
                      <PrototypeForm
                        disabled={disabled}
                        allowDeletingProperties={allowDeletingProperties}
                        onDeleteProperty={(property) =>
                          handleDeleteProperty(form.id, property)
                        }
                        assetProperties={addFormIdToFields(
                          form.properties,
                          form.id
                        ).map((field) => ({
                          ...field,
                          id: field?.id,
                        }))}
                        editable={true}
                        noDefaultDateValue={true}
                        taskPreview={taskPreview}
                      />
                    </div>
                  );
                })}
            </>
          );
        }}
      </Form>
      {showSelectForms && <hr className={styles.separator} />}
      <div className={styles.start_row}></div>
      {showSelectForms && (
        <div className={styles.row}>
          <Form enableReinitialize>
            <FormControl className={styles.dropdown_config}>
              <FieldLabel>
                {t("select task form")}
                <span className={styles.red}> *</span>
              </FieldLabel>
              <SortableSelect
                // name="formIds"
                options={
                  deprecatedForms.length > 0
                    ? [
                        ...deprecatedForms,
                        ...forms
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((el) => ({
                            value: el.id,
                            label: el.name,
                            global: el.global,
                          })),
                      ]
                    : forms
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((el) => ({
                          value: el.id,
                          label: el.name,
                          global: el.global,
                        }))
                }
                selected={selectedForms}
                onChange={onChange}
                onSortEnd={onSortEnd}
                disabled={previewMode}
              />
              <FieldError name="formIds" />
            </FormControl>
          </Form>
        </div>
      )}

      {!!taskPrototype && !noRulesEditor && (
        <RulesEditor
          prototype={taskPrototype}
          toggleRulesEditorOpen={toggleRulesEditorOpen}
        />
      )}

      {!!taskPrototype && !!taskTypeEditError && (
        <FormEditErrorModal
          onClose={() => {
            dispatch(taskPrototypesEditErrorClear());
            setShowTaskTypeEditErrorModal(false);
          }}
          isOpen={showTaskTypeEditErrorModal}
          form={taskPrototype}
          errors={taskTypeEditError}
        />
      )}
    </>
  );
};

export default TaskPrototypeForm;
