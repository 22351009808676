import axios from "axios";
import {
  refreshToken,
  refreshSuccess,
  accountNotActivatedError,
} from "./auth/authActions";
import { createToast } from "./toast/toastActions";

const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

export const createInterceptors = (store) => {
  const { getState, dispatch } = store;

  // REQUEST INTERCEPTOR
  axiosApiInstance.interceptors.request.use(
    (config) => {
      if (/^(?!\/auth).*$/.test(config.url)) {
        config.headers["Authorization"] = `Bearer ${
          getState().auth.info.token
        }`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // RESPONSE INTERCEPTOR
  axiosApiInstance.interceptors.response.use(
    (response) => response,
    async function (error) {
      const { config, response } = error;
      if (
        response?.status === 401 &&
        !config.retry &&
        /^(?!\/auth).*$/.test(config.url)
      ) {
        config.retry = true;
        const { data } = await refreshToken();
        dispatch(refreshSuccess(data));
        config.headers["Authorization"] = `Bearer ${data.token}`;
        return axiosApiInstance(config);
      } else if (/login/.test(config.url) && response?.status === 417) {
        const { dispatch } = store;
        dispatch(accountNotActivatedError(true));
      } else if (
        /^(?!\/auth\/rememberMe).*$/.test(config.url) &&
        response?.status !== 409 &&
        response?.status !== 403
      ) {
        const { dispatch } = store;
        dispatch(
          createToast({
            type: "error",
            title: "Error. Please try again later.",
          })
        );
      } else if (response?.status === 403) {
        window.location.href = `${window.location.origin}/login`;
      }
      return Promise.reject(error);
    }
  );
};

export default axiosApiInstance;
