import React, { useContext } from "react";
import PropTypes from "prop-types";
import { appUrls } from "../../urls";
import Entry from "../../layouts/Entry";
import { Link, useLocation } from "react-router-dom";
import Icon from "../../components/Icon";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./CheckEmail.module.scss";

const CheckEmail = () => {
  const { t } = useContext(I18nContext);
  const { state } = useLocation();

  return (
    <Entry>
      <div className={styles.container}>
        <span className={styles.icon_circle}>
          <Icon className={styles.icon} name="envelope" />
        </span>
        <span className={styles.header}>Check your e-mail</span>
        <span className={styles.txt_container}>
          {t("we’ve sent an e-mail to")}:
          <span className={styles.txt_green}>{state?.email}</span>
        </span>
        <span className={styles.txt_1}>
          {t("click the link in the e-mail to reset your password")}.
        </span>
        <span className={styles.txt_2}>
          {t("if you don’t see the e-mail, please check other places")}.
        </span>
        <span className={styles.txt}>
          {t("it might be in spam or other folders", "lower")}.
        </span>

        <Link className={styles.link_return} to={appUrls.LOGIN}>
          {t("back to login")}
        </Link>
      </div>
    </Entry>
  );
};
CheckEmail.propTypes = {
  user: PropTypes.object,
};

export default CheckEmail;
