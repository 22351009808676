export const prepareStatesOptions = (t, states, topLevel) => {
  const stateItems = {
    FOR_CREATION: t("for creation"),
    ACTIVE: t("active"),
    MARKED_DEFECTIVE: topLevel ? t("not active") : t("marked defective"),
    DEFECTIVE: t("defective"),
    FOR_REMOVE: t("for remove"),
    REMOVED: t("removed"),
  };

  return states.map((state) => ({
    value: state,
    label: stateItems[state],
  }));
};

export const prepareStateEventsOptions = (t, stateEvents) => {
  const stateEventsItems = {
    CREATE: t("create"),
    ACTIVATE: t("activate"),
    DECLINE_CREATION: t("decline creation"),
    MARK_DEFECTIVE: t("mark defective"),
    CONFIRM_DEFECTIVE: t("remove"),
    FIX: t("fix"),
    MISS: t("mark removed"),
    DECLINE_REMOVE: t("decline remove"),
    CONFIRM_REMOVE: t("confirm remove"),
  };

  return stateEvents.map((stateEvent) => ({
    value: stateEvent,
    label: stateEventsItems[stateEvent],
  }));
};
