import jwtDecode from "jwt-decode";
import Actions from "../actions";

const initialState = {
  info: null,
  error: null,
  isLogged: false,
  loginComplete: false,
  showAccountNotActivatedError: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOGIN_SUCCESS: {
      return {
        ...state,
        info: {
          ...jwtDecode(action.payload.token),
          ...action.payload,
        },
      };
    }
    case Actions.LOGIN_SUCCESS_FOR_DELETING: {
      return {
        ...state,
        isLogged: false,
        info: {
          ...jwtDecode(action.payload.token),
          ...action.payload,
        },
      };
    }
    case Actions.LOGIN_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.ACCOUNT_NOT_ACTIVATED_ERROR: {
      return {
        ...state,
        showAccountNotActivatedError: action.payload,
      };
    }
    case Actions.CLEAR_AUTH_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    case Actions.SELECT_COMPANIES_SUCCESS: {
      return {
        ...state,
        info: action.payload,
      };
    }
    case Actions.SELECT_REGION_SUCCESS: {
      return {
        ...state,
        loginComplete: true,
        info: action.payload,
      };
    }
    case Actions.REFRESH_SUCCESS: {
      return {
        ...state,
        info: action.payload,
      };
    }
    case Actions.LOGOUT: {
      return {
        ...state,
        isLogged: false,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
