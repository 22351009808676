import Actions from "../actions";
import axios from "../auth";
import history from "../../history";
import { appUrls } from "../../urls";

const fetchCompaniesSuccess = (companies) => {
  return {
    type: Actions.FETCH_COMPANIES_SUCCESS,
    payload: companies,
  };
};

const selectCompanySuccess = (data) => {
  return {
    type: Actions.SELECT_COMPANIES_SUCCESS,
    payload: data,
  };
};

const selectCompanyFailure = (error) => {
  return {
    type: Actions.SELECT_COMPANIES_FAILURE,
    payload: error,
  };
};

const fetchCompaniesFailure = (error) => {
  return {
    type: Actions.FETCH_COMPANIES_FAILURE,
    payload: error,
  };
};

export const fetchCompanies = () => {
  return (dispatch) => {
    return axios
      .get(`/chooseCompany`)
      .then((response) => {
        dispatch(fetchCompaniesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchCompaniesFailure(error));
      });
  };
};

export const selectCompany = (companyId, companyName) => {
  return (dispatch) => {
    return axios
      .post(`/submitCompany`, { companyId, companyName })
      .then(({ data }) => {
        dispatch(selectCompanySuccess(data));
        history.push(appUrls.REGION);
      })
      .catch((error) => {
        dispatch(selectCompanyFailure(error));
        history.push(appUrls.LOGIN);
      });
  };
};
