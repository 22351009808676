import React, { useContext, useState } from "react";
import styles from "./TaskHistory.module.scss";
import { useSelector } from "react-redux";
import { I18nContext } from "../../i18n/I18nContext";
import Icon from "../Icon";
import moment from "moment-timezone";
import { setUTCDate } from "../../helpers/helpers";

const TaskHistory = ({ created, createdBy, history }) => {
  const { t } = useContext(I18nContext);
  const { profile } = useSelector((state) => state.profile);
  const [historyOpened, setHistoryOpened] = useState(false);

  const statusToText = (status) => {
    switch (status) {
      case "COMPLETE":
        return "done";
      case "REOPEN":
        return "undo";
      case "CANCEL":
        return "cancel";
      case "UNDOABLE":
        return "cannot be done";
      default:
        return status;
    }
  };

  return (
    <div className={styles.task_history}>
      <div
        className={styles.task_history_header}
        onClick={() => setHistoryOpened(!historyOpened)}
      >
        <div className={styles.task_history_header_title}>
          {t("task history")}
        </div>
        <div className={styles.task_history_header_icon}>
          {historyOpened ? (
            <Icon name="chevron-down" className={styles.task_history_icon_up} />
          ) : (
            <Icon name="chevron-down" />
          )}
        </div>
      </div>
      {!!historyOpened && (
        <div className={styles.task_history_events_list}>
          <div className={styles.task_history_event}>
            {`${t("create", "upper")} - `}
            {createdBy}
            {" - "}
            {moment
              .tz(setUTCDate(created), profile.timeZone)
              .format()
              .split("T")
              .shift()}{" "}
            {moment
              .tz(setUTCDate(created), profile.timeZone)
              .format()
              .split("T")
              .pop()
              .slice(0, 5)}
          </div>
          {history.map((historyEvent, index) => (
            <div key={index} className={styles.task_history_event}>
              {t(statusToText(historyEvent.type), "upper")}
              {" - "}
              {historyEvent.user.userName
                ? historyEvent.user.userName
                : t("status changed by system")}
              {historyEvent.user.userName &&
              historyEvent.reason &&
              historyEvent.reason !== "''"
                ? `: ${historyEvent.reason}`
                : ""}
              {" - "}
              {moment
                .tz(setUTCDate(historyEvent.occurredAt), profile.timeZone)
                .format()
                .split("T")
                .shift()}{" "}
              {moment
                .tz(setUTCDate(historyEvent.occurredAt), profile.timeZone)
                .format()
                .split("T")
                .pop()
                .slice(0, 5)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TaskHistory;
