const Actions = {
  ASSETS_LOADING_START: "ASSETS_LOADING_START",
  ASSETS_LOADING_STOP: "ASSETS_LOADING_STOP",
  ADD_ASSET_SUCCESS: "ADD_ASSET_SUCCESS",
  FETCH_ASSET_TASKS_SUCCESS: "FETCH_ASSET_TASKS_SUCCESS",
  FETCH_ASSET_TASKS_REQUEST: "FETCH_ASSET_TASKS_REQUEST",
  FETCH_ASSET_TASKS_ERROR: "FETCH_ASSET_TASKS_ERROR",
  FETCH_ASSETS_SUCCESS: "FETCH_ASSETS_SUCCESS",
  FETCH_RECURRENCE_TASKS_SUCCESS: "FETCH_RECURRENCE_TASKS_SUCCESS",
  FETCH_RECURRENCE_TASKS_REQUEST: "FETCH_RECURRENCE_TASKS_REQUEST",
  FETCH_RECURRENCE_TASKS_ERROR: "FETCH_RECURRENCE_TASKS_ERROR",
  FETCH_TOP_ASSETS_SUCCESS: "FETCH_TOP_ASSETS_SUCCESS",
  FETCH_ALL_ASSETS_REQUEST: "FETCH_ALL_ASSETS_REQUEST",
  FEATCH_ASSET_DETAILS_SUCCESS: "FEATCH_ASSET_DETAILS_SUCCESS",
  FETCH_ASSETS_SEARCH_SUCCESS: "FETCH_ASSETS_SEARCH_SUCCESS",
  CLEAR_SEARCH: "CLEAR_SEARCH",
  EDIT_ASSET_SUCCESS: "EDIT_ASSET_SUCCESS",
  ASSETS_NAMES_LOADING_START: "ASSETS_NAMES_LOADING_START",
  FETCH_ASSETS_NAMES_SUCCESS: "FETCH_ASSETS_NAMES_SUCCESS",
  FETCH_ASSETS_NAMES_FAILURE: "FETCH_ASSETS_NAMES_FAILURE",
  SELECT_LAYOUT: "SELECT_LAYOUT",
  FETCH_UNFILTERED_ASSETS_FOR_GROUP_TASK_SUCCESS:
    "FETCH_UNFILTERED_ASSETS_FOR_GROUP_TASK_SUCCESS",
  FETCH_ASSETS_FOR_GROUP_TASK_SUCCESS: "FETCH_ASSETS_FOR_GROUP_TASK_SUCCESS",
  FETCH_GROUP_TASKS_SUCCESS: "FETCH_GROUP_TASKS_SUCCESS",
  STATE_CHANGE_START: "STATE_CHANGE_START",
  STATE_CHANGE_STOP: "STATE_CHANGE_STOP",

  FETCH_PROTOTYPES_SUCCESS: "FETCH_PROTOTYPES_SUCCESS",
  FETCH_PROTOTYPES_USED_WITHIN_COMPANY_SUCCESS:
    "FETCH_PROTOTYPES_USED_WITHIN_COMPANY_SUCCESS",
  FETCH_PROTOTYPES_USED_WITHIN_PARENT_ASSET_SUCCESS:
    "FETCH_PROTOTYPES_USED_WITHIN_PARENT_ASSET_SUCCESS",
  FETCH_TOP_PROTOTYPES_SUCCESS: "FETCH_TOP_PROTOTYPES_SUCCESS",
  FETCH_NOT_TOP_PROTOTYPES_SUCCESS: "FETCH_NOT_TOP_PROTOTYPES_SUCCESS",
  FETCH_PROTOTYPES_FAILURE: "FETCH_PROTOTYPES_FAILURE",
  EDIT_ASSET_PROTOTYPE_SUCCESS: "EDIT_ASSET_PROTOTYPE_SUCCESS",
  ASSET_PROTOTYPES_REQUEST_FAILURE: "ASSET_PROTOTYPES_REQUEST_FAILURE",
  ASSET_PROTOTYPES_EDIT_ERROR_CLEAR: "ASSET_PROTOTYPES_EDIT_ERROR_CLEAR",

  //toeast errors
  CREATE_TOAST: "CREATE_TOAST",
  DELETE_TOAST: "DELETE_TOAST",
  EDIT_USERS_RIGHTS_FAILURE: "EDIT_USERS_RIGHTS_FAILURE",
  EDIT_USERS_RIGHTS_SUCCESS: "EDIT_USERS_RIGHTS_SUCCESS",

  LOGIN_ERROR: "LOGIN_ERROR",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_SUCCESS_FOR_DELETING: "LOGIN_SUCCESS_FOR_DELETING",
  REFRESH_SUCCESS: "REFRESH_SUCCESS",
  LOGOUT: "LOGOUT",

  FETCH_RULES_SUCCESS: "FETCH_RULES_SUCCESS",
  FETCH_RULES_REQUEST: "FETCH_RULES_REQUEST",
  FETCH_RULES_ERROR: "FETCH_RULES_ERROR",
  FETCH_TASK_TYPES_WITH_RULES_SUCCESS: "FETCH_TASK_TYPES_WITH_RULES_SUCCESS",
  FETCH_TASK_TYPES_WITH_RULES_REQUEST: "FETCH_TASK_TYPES_WITH_RULES_REQUEST",
  FETCH_TASK_TYPES_WITH_RULES_ERROR: "FETCH_TASK_TYPES_WITH_RULES_ERROR",

  FETCH_FORMS_SUCCESS: "FETCH_FORMS_SUCCESS",
  FETCH_FORMS_BY_COMPANY_SUCCESS: "FETCH_FORMS_BY_COMPANY_SUCCESS",
  FETCH_FORMS_REQUEST: "FETCH_FORMS_REQUEST",
  FORMS_REQUEST_SUCCESS: "FORMS_REQUEST_SUCCESS",
  FORMS_REQUEST_FAILURE: "FORMS_REQUEST_FAILURE",
  FORMS_EDIT_ERROR_CLEAR: "FORMS_EDIT_ERROR_CLEAR",

  FETCH_TASK_PROTOTYPES_REQUEST: "FETCH_TASK_PROTOTYPES_REQUEST",
  FETCH_TASK_PROTOTYPES_SUCCESS: "FETCH_TASK_PROTOTYPES_SUCCESS",
  FETCH_TASK_PROTOTYPES_ERROR: "FETCH_TASK_PROTOTYPES_ERROR",
  TASK_PROTOTYPES_REQUEST_FAILURE: "TASK_PROTOTYPES_REQUEST_FAILURE",
  TASK_PROTOTYPES_EDIT_ERROR_CLEAR: "TASK_PROTOTYPES_EDIT_ERROR_CLEAR",

  FETCH_TASKS_SUCCESS: "FETCH_TASKS_SUCCESS",
  FETCH_TASKS_ERROR: "FETCH_TASKS_ERROR",
  FETCH_TASKS_REQUEST: "FETCH_TASKS_REQUEST",
  FETCH_BATCHTASKS_SUCCESS: "FETCH_BATCHTASKS_SUCCESS",
  FETCH_BATCHTASKS_ERROR: "FETCH_BATCHTASKS_ERROR",
  FETCH_BATCHTASKS_REQUEST: "FETCH_BATCHTASKS_REQUEST",
  CLEAR_BATCHTASKS_REQUEST: "CLEAR_BATCHTASKS_REQUEST",
  TASKS_LOADING_START: "TASKS_LOADING_START",
  TASKS_LOADING_STOP: "TASKS_LOADING_STOP",
  TASKS_RECENT_LOADING_START: "TASKS_RECENT_LOADING_START",
  TASKS_RECENT_LOADING_STOP: "TASKS_RECENT_LOADING_STOP",
  TASKS_READY_LOADING_START: "TASKS_READY_LOADING_START",
  TASKS_READY_LOADING_STOP: "TASKS_READY_LOADING_STOP",

  FETCH_REPORT_REQUEST: "FETCH_REPORT_REQUEST",
  FETCH_REPORT_FINISHED: "FETCH_REPORT_FINISHED",

  FETCH_RIGHTS_REQUEST: "FETCH_RIGHTS_REQUEST",
  FETCH_RIGHTS_SUCCESS: "FETCH_RIGHTS_SUCCESS",
  FETCH_RIGHTS_ERROR: "FETCH_RIGHTS_ERROR",

  FETCH_RIGHTS_TASK_TEMPLATE_LIST_REQUEST:
    "FETCH_RIGHTS_TASK_TEMPLATE_LIST_REQUEST",
  FETCH_RIGHTS_TASK_TEMPLATE_LIST_SUCCESS:
    "FETCH_RIGHTS_TASK_TEMPLATE_LIST_SUCCESS",
  FETCH_RIGHTS_TASK_TEMPLATE_LIST_ERROR:
    "FETCH_RIGHTS_TASK_TEMPLATE_LIST_ERROR",

  FETCH_RIGHTS_ASSET_TEMPLATE_LIST_REQUEST:
    "FETCH_RIGHTS_ASSET_TEMPLATE_LIST_REQUEST",
  FETCH_RIGHTS_ASSET_TEMPLATE_LIST_SUCCESS:
    "FETCH_RIGHTS_ASSET_TEMPLATE_LIST_SUCCESS",
  FETCH_RIGHTS_ASSET_TEMPLATE_LIST_ERROR:
    "FETCH_RIGHTS_ASSET_TEMPLATE_LIST_ERROR",

  SELECT_COMPANIES_SUCCESS: "SELECT_COMPANIES_SUCCESS",
  SELECT_COMPANIES_FAILURE: "SELECT_COMPANIES_FAILURE",
  FETCH_COMPANIES_SUCCESS: "FETCH_COMPANIES_SUCCESS",
  FETCH_COMPANIES_FAILURE: "FETCH_COMPANIES_FAILURE",

  SELECT_REGION_SUCCESS: "SELECT_REGION_SUCCESS",
  SELECT_REGION_FAILURE: "SELECT_REGION_FAILURE",
  FETCH_REGIONS_SUCCESS: "FETCH_REGIONS_SUCCESS",
  FETCH_REGIONS_FAILURE: "FETCH_REGIONS_FAILURE",

  PASSWORD_RESET_ERROR: "PASSWORD_RESET_ERROR",
  PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",
  PASSWORD_TOKEN_SUCCESS: "PASSWORD_TOKEN_SUCCESS",
  PASSWORD_TOKEN_ERROR: "PASSWORD_TOKEN_ERROR",

  CONFIRM_ACCOUNT_SUCCESS: "CONFIRM_ACCOUNT_SUCCESS",
  CONFIRM_ACCOUNT_ERROR: "CONFIRM_ACCOUNT_ERROR",

  ACCOUNT_NOT_ACTIVATED_ERROR: "ACCOUNT_NOT_ACTIVATED_ERROR",
  CLEAR_AUTH_ERROR: "CLEAR_AUTH_ERROR",

  FETCH_UNITS_FAILURE: "FETCH_UNITS_FAILURE",
  FETCH_UNITS_SUCCESS: "FETCH_UNITS_SUCCESS",

  FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
  FETCH_USERS_FAILURE: "FETCH_USERS_FAILURE",

  FETCH_USERS_WITH_ASSET_ACCESS_SUCCESS:
    "FETCH_USERS_WITH_ASSET_ACCESS_SUCCESS",
  FETCH_USERS_WITH_ASSET_ACCESS_FAILURE:
    "FETCH_USERS_WITH_ASSET_ACCESS_FAILURE",

  USER_ACTION_PENDING: "USER_ACTION_PENDING",
  USER_ACTION_SUCCESS: "USER_ACTION_SUCCESS",

  FETCH_PROFILE_SUCCESS: "FETCH_PROFILE_SUCCESS",
  FETCH_PROFILE_FAILURE: "FETCH_PROFILE_FAILURE",

  FETCH_REGISTERED_ROLES_SUCCESS: "FETCH_REGISTERED_ROLES_SUCCESS",
  FETCH_REGISTERED_ROLES_FAILURE: "FETCH_REGISTERED_ROLES_FAILURE",
  FETCH_REGISTERED_COMPANIES_SUCCESS: "FETCH_REGISTERED_COMPANIES_SUCCESS",
  FETCH_REGISTERED_COMPANIES_FAILURE: "FETCH_REGISTERED_COMPANIES_FAILURE",

  FETCH_MAINTAINER_ORGANIZATIONS_RIGHTS_SUCCESS:
    "FETCH_MAINTAINER_ORGANIZATIONS_RIGHTS_SUCCESS",
  FETCH_MAINTAINER_ORGANIZATIONS_RIGHTS_REQUEST:
    "FETCH_MAINTAINER_ORGANIZATIONS_RIGHTS_REQUEST",
  FETCH_MAINTAINER_ORGANIZATIONS_RIGHTS_ERROR:
    "FETCH_MAINTAINER_ORGANIZATIONS_RIGHTS_ERROR",
  FETCH_MAINTAINER_ORGANIZATIONS_SUCCESS:
    "FETCH_MAINTAINER_ORGANIZATIONS_SUCCESS",
  FETCH_MAINTAINER_ORGANIZATIONS_REQUEST:
    "FETCH_MAINTAINER_ORGANIZATIONS_REQUEST",
  FETCH_MAINTAINER_ORGANIZATIONS_ERROR: "FETCH_MAINTAINER_ORGANIZATIONS_ERROR",

  // license
  FETCH_LICENSE_REQUEST: "FETCH_LICENSE_REQUEST",
  FETCH_LICENSE_SUCCESS: "FETCH_LICENSE_SUCCESS:",
  FETCH_LICENSE_LIST_SUCCESS: "FETCH_LICENSE_LIST_SUCCESS",
  FETCH_LICENSE_LIST_REQUEST: "FETCH_LICENSE_LIST_REQUEST",

  // buildings
  SET_BUILDINGS: "SET_BUILDINGS",
  CLEAR_BUILDINGS: "CLEAR_BUILDINGS",

  // icons
  FETCH_ICONS_SUCCESS: "FETCH_ICONS_SUCCESS",
  FETCH_ICONS_FAILURE: "FETCH_ICONS_FAILURE",

  // attachments explorer
  FETCH_ATTACHMENTS_START: "FETCH_ATTACHMENTS_START",
  FETCH_ATTACHMENTS_SUCCESS: "FETCH_ATTACHMENTS_SUCCESS",
  FETCH_ATTACHMENTS_FAILURE: "FETCH_ATTACHMENTS_FAILURE",
  FETCH_ATTACHMENTS_FOLDERS_SUCCESS: "FETCH_ATTACHMENTS_FOLDERS_SUCCESS",
  FETCH_ATTACHMENTS_TYPES_SUCCESS: "FETCH_ATTACHMENTS_TYPES_SUCCESS",
  ATTACHMENTS_DELETE_START: "ATTACHMENTS_DELETE_START",
  ATTACHMENTS_DELETE_STOP: "ATTACHMENTS_DELETE_STOP",
};
export default Actions;
