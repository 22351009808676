import React, { useContext, useState } from "react";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./RequirementsEditor.module.scss";
import PreconditionsEditor from "./PreconditionsEditor";
import Select from "react-select";

const RequirementsEditor = (props) => {
  const {
    requirements,
    properties,
    updateRequirements,
    readOnly,
    assetPrototype,
    recurring,
  } = props;
  const { t } = useContext(I18nContext);
  const stateType = assetPrototype ? "assetState" : "taskState";

  const [requirementsList, setRequirementsList] = useState(
    requirements ? requirements : [{ preconditions: [] }]
  );
  const [showStateCondition, setShowStateCondition] = useState(
    requirements
      .filter((requirement) => !!requirement[stateType])
      .map((requirement, index) => index)
  );

  const taskStateOptions = recurring
    ? [
        { value: "CANCELLED", label: t("cancelled") },
        { value: "COMPLETED", label: t("ready") },
        { value: "MISSED", label: t("missed") },
        { value: "CANT_DO", label: t("cannot be done") },
      ]
    : [
        { value: "OVERDUE", label: t("overdue") },
        { value: "CANCELLED", label: t("cancelled") },
        { value: "COMPLETED", label: t("ready") },
        { value: "CANT_DO", label: t("cannot be done") },
        { value: "DONE_TOO_LATE", label: t("done too late") },
      ];

  const assetStateOptions = [
    { value: "FOR_CREATION", label: t("for creation") },
    { value: "MARKED_DEFECTIVE", label: t("marked defective") },
    { value: "DEFECTIVE", label: t("defective") },
    { value: "FOR_REMOVE", label: t("for remove") },
    { value: "REMOVED", label: t("removed") },
  ];

  const addNewRequirementForm = () => {
    setRequirementsList([...requirements, { preconditions: [] }]);
  };

  const updateRequirement = (preconditions, index) => {
    if (typeof index !== "undefined") {
      const newRequirements = [...requirementsList];
      newRequirements[index] = {
        ...newRequirements[index],
        preconditions: preconditions,
      };
      setRequirementsList(newRequirements);
      updateRequirements(newRequirements);
    } else {
      const newRequirements = [
        ...requirementsList,
        {
          preconditions: preconditions,
        },
      ];
      setRequirementsList(newRequirements);
      updateRequirements(newRequirements);
    }
  };

  const removeRequirement = (index) => {
    const newRequirements = [...requirementsList];
    newRequirements.splice(index, 1);
    setRequirementsList(newRequirements);
    updateRequirements(newRequirements);
  };

  const toggleStateCondition = (value, index) => {
    const newShowStateCondition = [...showStateCondition];
    if (!!value) {
      newShowStateCondition.push(index);
    } else {
      const stateIndex = newShowStateCondition.indexOf(index);
      newShowStateCondition.splice(stateIndex, 1);
      changeStateCondition(null, index);
    }
    setShowStateCondition(newShowStateCondition);
  };

  const changeStateCondition = (value, index) => {
    const newRequirements = [...requirementsList];

    if (!!value) {
      newRequirements[index][stateType] = value;
    } else {
      delete newRequirements[index][stateType];
    }

    updateRequirements(newRequirements);
  };

  const selectStyles = {
    option: (styles, { isSelected, data }) => {
      return {
        ...styles,
        display: "flex",
        alignItems: "center",
        color: "#393939",
        backgroundColor: data.global ? "#fff8f1" : "#ffffff",
        fontWeight: isSelected ? "500" : "400",
        height: "auto",
        "&:hover": {
          backgroundColor: "#eaeaea",
          color: "#393939",
          fontWeight: "500",
          cursor: "pointer",
        },
      };
    },
    control: (base, state, provided) => ({
      ...provided,
      paddingLeft: "0px",
      fontWeight: "400",
      fontSize: "14px",
      minWidth: "200px",
      height: "38px",
      minHeight: "36px",
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      borderColor: state.isFocused ? "#EE7203" : base.borderColor,
      "&:hover": {
        borderColor: state.isFocused ? "#EE7203" : base.borderColor,
        cursor: "pointer",
      },
    }),
    valueContainer: (base, state, provided) => ({
      ...provided,
      ...base,
      backgroundColor: state.isDisabled ? "#f4f4f4" : "#ffffff",
      borderRadius: "3px",
      height: "100%",
      overflowY: "auto",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#D8E6FF",
      };
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      backgroundColor: "#D8E6FF",
      color: "#191919",
      "&:hover": {
        backgroundColor: "#D8E6FF",
        color: "#191919",
      },
    }),
    menu: (provided) => {
      return {
        ...provided,
        zIndex: "10",
        border: "1px solid #EE7203",
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        marginLeft: "2px",
        lineHeight: "16px",
      };
    },
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        backgroundColor: "none",
      };
    },
    dropdownIndicator: (base, state) => {
      return {
        ...base,
        color: state.isFocused ? "#EE7203" : "#AAAAAA",
      };
    },
    placeholder: (provided) => {
      return {
        ...provided,
        marginLeft: "2px",
        color: "#AAAAAA",
        fontWeight: "400",
      };
    },
  };

  return (
    <div className={styles.requirements_editor}>
      {!!requirementsList.length &&
        requirementsList.map((requirement, index) => (
          <div key={index}>
            <div className={styles.requirements_form}>
              <div className={styles.row_vertical}>
                <div className={styles.row_vertical_no_bottom_margin}>
                  <input
                    id="stateCondition"
                    name="stateCondition"
                    type="checkbox"
                    className={styles.checkbox}
                    onChange={(e) => {
                      toggleStateCondition(e.target.checked, index);
                    }}
                    checked={showStateCondition.includes(index)}
                    disabled={readOnly}
                  />
                  <label
                    className={
                      readOnly
                        ? styles.input_label
                        : styles.input_label_checkbox
                    }
                    htmlFor={"stateCondition"}
                  >
                    {assetPrototype
                      ? t("include asset state condition")
                      : t("include task state condition")}
                  </label>
                </div>
                {showStateCondition.includes(index) && (
                  <div className={styles.row_vertical_no_bottom_margin}>
                    <Select
                      styles={selectStyles}
                      menuPosition={"relative"}
                      isDisabled={readOnly}
                      value={
                        assetPrototype
                          ? assetStateOptions.find(
                              (option) =>
                                option.value ===
                                requirementsList[index].assetState
                            )
                          : taskStateOptions.find(
                              (option) =>
                                option.value ===
                                requirementsList[index].taskState
                            )
                      }
                      maxMenuHeight={200}
                      onChange={(selected) =>
                        changeStateCondition(selected.value, index)
                      }
                      options={
                        assetPrototype ? assetStateOptions : taskStateOptions
                      }
                      placeholder={t("select")}
                      noOptionsMessage={() => t("no options")}
                    />
                  </div>
                )}
              </div>

              {(!!requirementsList[index].assetState ||
                (!!requirementsList[index].taskState &&
                  (requirementsList[index].taskState === "COMPLETED" ||
                    requirementsList[index].taskState === "DONE_TOO_LATE"))) &&
                !!properties.length && (
                  <div className={styles.or_separator}>{t("and", "upper")}</div>
                )}
              {!!properties.length &&
                (requirementsList[index].taskState === "COMPLETED" ||
                  requirementsList[index].taskState === "DONE_TOO_LATE" ||
                  !!requirementsList[index].assetState ||
                  (!requirementsList[index].taskState &&
                    !requirementsList[index].assetState)) && (
                  <PreconditionsEditor
                    requirementIndex={index}
                    requirement={requirement}
                    properties={properties}
                    updateRequirement={updateRequirement}
                    removeRequirement={removeRequirement}
                    readOnly={readOnly}
                  />
                )}
            </div>
            {index !== requirementsList.length - 1 && (
              <div className={styles.or_separator}>{t("or", "upper")}</div>
            )}
          </div>
        ))}

      {!!requirementsList.length &&
        !readOnly &&
        !!requirementsList.at(-1).preconditions.length && (
          <div
            className={styles.add_requirement_button}
            onClick={addNewRequirementForm}
          >
            {`+ ${t("or", "upper")}`}
          </div>
        )}
    </div>
  );
};

export default RequirementsEditor;
