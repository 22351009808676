import Icon from "../Icon";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./DashBoardFooter.module.scss";
import { useContext } from "react";

const DashboardFooter = () => {
  const { t } = useContext(I18nContext);

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.footerContent}>
        <div className={styles.contained_cell}>
          <div className={styles.footContentItem}>
            <Icon name="questionMark-orange" className={styles.icon} />
            <span className={styles.callAction}>{t("search help centre")}</span>
            <span>{t("to learn more about how to do things")}.</span>
          </div>
        </div>
        <div className={styles.contained_cell}>
          <div className={styles.footContentItem}>
            <Icon name="loupe" className={styles.icon} />
            <span className={styles.callAction}>{t("check FAQ")}</span>
            <span>{t("for short answers to most frequent questions")}.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardFooter;
