import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./RuleItem.module.scss";
import RequirementsEditor from "./RequirementsEditor";
import Icon from "../Icon";
import Button from "../Button";
import Select from "react-select";
import DeleteRuleModal from "../Configuration/DeleteRuleModal";
import DisableRuleModal from "./DisableRuleModal";
import * as Yup from "yup";

const RuleItem = (props) => {
  const {
    rule,
    properties,
    createRule,
    editRule,
    deleteRule,
    readOnly,
    disablingAvailable,
    cancelMethod,
    showLocalLabels,
    formIsGlobal,
    assetPrototype,
    recurring,
    ruleNames,
    toggleRulesEditorOpen,
  } = props;
  const { t } = useContext(I18nContext);
  const taskPrototypes = useSelector((state) => state.taskPrototypes.items);
  const isPrivileged = useSelector(
    (state) => state.auth.info?.companyPrivileged
  );

  const taskPrototypeOptions = taskPrototypes
    .filter((prototype) => !prototype.deprecated)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((template) => ({
      value: template.id,
      label: template.name,
      global: template.global,
    }));

  const taskActionDelayUnitsOptions = [
    { value: "DAYS", label: t("days") },
    { value: "WEEKS", label: t("weeks") },
    { value: "MONTHS", label: t("months") },
    { value: "QUARTER_YEAR", label: t("quarter year") },
    { value: "HALF_YEAR", label: t("half year") },
    { value: "YEARS", label: t("years") },
  ];

  const criticalityOptions = [
    { value: "HIGH", label: t("high") },
    { value: "MEDIUM", label: t("medium") },
    { value: "LOW", label: t("low") },
    { value: "NONE", label: t("none") },
  ];

  const initialValues = {
    forUpdate: true,
    alertLevel: rule ? rule.alertLevel : "NONE",
    critical: rule ? rule.critical : false,
    name: rule ? rule.name : "",
    requirements: rule ? rule.requirements : [{ preconditions: [] }],
    sendNotifications: rule ? rule.sendNotifications : false,
    taskActions: rule && rule.taskActions ? rule.taskActions : [],
  };

  const [ruleItem, setRuleItem] = useState(initialValues);
  const [showDetails, setShowDetails] = useState(false);
  const [showRuleDeleteConfirmationModal, setShowRuleDeleteConfirmationModal] =
    useState(false);
  const [
    showRuleDisableConfirmationModal,
    setShowRuleDisableConfirmationModal,
  ] = useState(false);
  const [showRuleEnableConfirmationModal, setShowRuleEnableConfirmationModal] =
    useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [showNameUniqueError, setShowNameUniqueError] = useState(false);

  useEffect(() => {
    setRuleItem(initialValues);
  }, [rule]);

  useEffect(() => {
    if (toggleRulesEditorOpen) {
      toggleRulesEditorOpen(showDetails);
    }
  }, [showDetails]);

  const updateRequirements = (requirements) => {
    const newRuleItem = { ...ruleItem, requirements };
    setRuleItem(newRuleItem);
  };

  const updateTaskActionId = (type, id, index) => {
    const newTaskActions = [...ruleItem.taskActions];
    newTaskActions.forEach((action, index) => (action.id = index));
    const filteredByType = newTaskActions.filter(
      (action) => action.type === type
    );

    newTaskActions[
      newTaskActions.findIndex(
        (action) => action.id === filteredByType[index].id
      )
    ].taskTemplateId = id;
    newTaskActions.forEach((action) => delete action.id);
    setRuleItem({ ...ruleItem, taskActions: newTaskActions });
  };

  const updateTaskActionDelay = (type, delay, index) => {
    const newTaskActions = [...ruleItem.taskActions];
    newTaskActions.forEach((action, index) => (action.id = index));
    const filteredByType = newTaskActions.filter(
      (action) => action.type === type
    );

    newTaskActions[
      newTaskActions.findIndex(
        (action) => action.id === filteredByType[index].id
      )
    ].delay = delay;
    newTaskActions.forEach((action) => delete action.id);
    setRuleItem({ ...ruleItem, taskActions: newTaskActions });
  };

  const updateTaskActionDelayUnit = (type, delayUnit, index) => {
    const newTaskActions = [...ruleItem.taskActions];
    newTaskActions.forEach((action, index) => (action.id = index));
    const filteredByType = newTaskActions.filter(
      (action) => action.type === type
    );

    newTaskActions[
      newTaskActions.findIndex(
        (action) => action.id === filteredByType[index].id
      )
    ].delayUnit = delayUnit;
    newTaskActions.forEach((action) => delete action.id);
    setRuleItem({ ...ruleItem, taskActions: newTaskActions });
  };

  const updateTaskActionEnd = (type, end, index) => {
    const newTaskActions = [...ruleItem.taskActions];
    newTaskActions.forEach((action, index) => (action.id = index));
    const filteredByType = newTaskActions.filter(
      (action) => action.type === type
    );

    newTaskActions[
      newTaskActions.findIndex(
        (action) => action.id === filteredByType[index].id
      )
    ].end = end;
    newTaskActions.forEach((action) => delete action.id);
    setRuleItem({ ...ruleItem, taskActions: newTaskActions });
  };

  const updateTaskActionEndUnit = (type, endUnit, index) => {
    const newTaskActions = [...ruleItem.taskActions];
    newTaskActions.forEach((action, index) => (action.id = index));
    const filteredByType = newTaskActions.filter(
      (action) => action.type === type
    );

    newTaskActions[
      newTaskActions.findIndex(
        (action) => action.id === filteredByType[index].id
      )
    ].endUnit = endUnit;
    newTaskActions.forEach((action) => delete action.id);
    setRuleItem({ ...ruleItem, taskActions: newTaskActions });
  };

  const addNewTaskAction = (type) => {
    const newTaskActions = [...ruleItem.taskActions];
    const newTaskActionItem = {
      taskTemplateId: taskPrototypeOptions[0].value,
      delay: 0,
      delayUnit: "DAYS",
      type,
    };
    if (type === "CREATE") {
      newTaskActionItem.end = 1;
      newTaskActionItem.endUnit = "DAYS";
    }
    newTaskActions.push(newTaskActionItem);
    setRuleItem({ ...ruleItem, taskActions: newTaskActions });
  };

  const deleteTaskAction = (type, index) => {
    const newTaskActions = [...ruleItem.taskActions];
    newTaskActions.forEach((action, index) => (action.id = index));
    const filteredByType = newTaskActions.filter(
      (action) => action.type === type
    );
    const taskActionsWithoutDeleted = newTaskActions.filter(
      (action) => action.id !== filteredByType[index].id
    );
    setRuleItem({ ...ruleItem, taskActions: taskActionsWithoutDeleted });
  };

  const selectStyles = {
    option: (styles, { isSelected, data }) => {
      return {
        ...styles,
        display: "flex",
        alignItems: "center",
        color: "#393939",
        backgroundColor: data.global ? "#fff8f1" : "#ffffff",
        fontWeight: isSelected ? "500" : "400",
        height: "auto",
        "&:hover": {
          backgroundColor: "#eaeaea",
          color: "#393939",
          fontWeight: "500",
          cursor: "pointer",
        },
      };
    },
    control: (base, state, provided) => ({
      ...provided,
      paddingLeft: "0px",
      fontWeight: "400",
      fontSize: "14px",
      minWidth: "200px",
      height: "38px",
      minHeight: "36px",
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      borderColor: state.isFocused ? "#EE7203" : base.borderColor,
      "&:hover": {
        borderColor: state.isFocused ? "#EE7203" : base.borderColor,
        cursor: "pointer",
      },
    }),
    valueContainer: (base, state, provided) => ({
      ...provided,
      ...base,
      backgroundColor: state.isDisabled ? "#f4f4f4" : "#ffffff",
      borderRadius: "3px",
      height: "100%",
      overflowY: "auto",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#D8E6FF",
      };
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      backgroundColor: "#D8E6FF",
      color: "#191919",
      "&:hover": {
        backgroundColor: "#D8E6FF",
        color: "#191919",
      },
    }),
    menu: (provided) => {
      return {
        ...provided,
        zIndex: "10",
        border: "1px solid #EE7203",
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        marginLeft: "2px",
        lineHeight: "16px",
      };
    },
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        backgroundColor: "none",
      };
    },
    dropdownIndicator: (base, state) => {
      return {
        ...base,
        color: state.isFocused ? "#EE7203" : "#AAAAAA",
      };
    },
    placeholder: (provided) => {
      return {
        ...provided,
        marginLeft: "2px",
        color: "#AAAAAA",
        fontWeight: "400",
      };
    },
  };

  return (
    <div className={styles.rule_item}>
      {!!rule && (
        <div
          className={styles.header}
          onClick={() => setShowDetails(!showDetails)}
        >
          <div className={styles.header}>
            {rule && rule.name ? ruleItem.name : t("new rule")}
            {showLocalLabels && (
              <div
                className={
                  !rule.global || !formIsGlobal
                    ? styles.local_label
                    : styles.global_label
                }
              >
                {!rule.global || !formIsGlobal ? t("local") : t("global")}
              </div>
            )}
            {rule.disabled && (
              <div className={styles.disabled_label}>{t("disabled")}</div>
            )}
            {rule && !readOnly && !(rule.global && !isPrivileged) && (
              <div
                className={styles.delete_task_action_btn}
                onClick={() => setShowRuleDeleteConfirmationModal(true)}
              >
                <Icon name="x-delete" />
              </div>
            )}
            {rule && disablingAvailable && !rule.disabled && (
              <div
                className={styles.disable_rule_action_btn}
                onClick={() => setShowRuleDisableConfirmationModal(true)}
              >
                {t("disable rule")}
              </div>
            )}
            {rule && disablingAvailable && !!rule.disabled && (
              <div
                className={styles.disable_rule_action_btn}
                onClick={() => setShowRuleEnableConfirmationModal(true)}
              >
                {t("enable rule")}
              </div>
            )}
          </div>
          <Icon name="chevron-down" />
        </div>
      )}
      {(!rule || !!showDetails) && (
        <>
          <div className={styles.row}>
            <label className={styles.input_label}>
              {t("name")} <span className={styles.red}>*</span>
            </label>
            <input
              className={styles.text_input}
              type="text"
              onInput={(e) => {
                setShowNameError(false);
                setShowNameUniqueError(false);
                setRuleItem({ ...ruleItem, name: e.target.value });
              }}
              disabled={readOnly}
              defaultValue={ruleItem.name}
            />
            {showNameError && (
              <div className={styles.error}>
                {t("this field is required, please provide a value.")}
              </div>
            )}
            {showNameUniqueError && (
              <div className={styles.error}>{t("name must be unique.")}</div>
            )}
          </div>
          <div className={styles.row_no_bottom_margin}>
            <div className={styles.input_label}>{t("conditions")}</div>
          </div>
          <RequirementsEditor
            requirements={ruleItem.requirements}
            properties={properties}
            updateRequirements={updateRequirements}
            readOnly={readOnly}
            assetPrototype={assetPrototype}
            recurring={recurring}
          />

          {!!ruleItem &&
            !!ruleItem.taskActions &&
            !!ruleItem.taskActions.filter((action) => action.type === "CREATE")
              .length && (
              <div className={styles.row}>
                <label className={styles.input_label}>
                  {t("choose task to be started")}
                </label>
                {ruleItem.taskActions
                  .filter((action) => action.type === "CREATE")
                  .map((taskAction, index) => (
                    <div className={styles.task_action_row} key={index}>
                      <div className={styles.task_action_group_space_between}>
                        <Select
                          styles={selectStyles}
                          menuPosition={"relative"}
                          isDisabled={readOnly}
                          value={taskPrototypeOptions.find(
                            (option) =>
                              option.value === taskAction.taskTemplateId
                          )}
                          maxMenuHeight={200}
                          onChange={(selected) =>
                            updateTaskActionId("CREATE", selected.value, index)
                          }
                          options={taskPrototypeOptions}
                          placeholder={t("select")}
                          noOptionsMessage={() => t("no options")}
                        />
                        {!readOnly && (
                          <div
                            className={styles.delete_task_action_btn}
                            onClick={() => deleteTaskAction("CREATE", index)}
                          >
                            <Icon name="x-delete" />
                          </div>
                        )}
                      </div>
                      <div className={styles.task_action_group}>
                        <label className={styles.input_label_regular}>
                          {t("delay start by", "lower")}
                        </label>
                        <input
                          className={styles.text_input_margin}
                          type="number"
                          onInput={(e) =>
                            updateTaskActionDelay(
                              "CREATE",
                              Number(e.target.value),
                              index
                            )
                          }
                          disabled={readOnly}
                          defaultValue={taskAction.delay}
                        />
                        <Select
                          styles={selectStyles}
                          menuPosition={"relative"}
                          isDisabled={readOnly}
                          value={taskActionDelayUnitsOptions.find(
                            (option) => option.value === taskAction.delayUnit
                          )}
                          maxMenuHeight={200}
                          onChange={(selected) => {
                            updateTaskActionDelayUnit(
                              "CREATE",
                              selected.value,
                              index
                            );
                          }}
                          options={taskActionDelayUnitsOptions}
                          placeholder={t("select")}
                          noOptionsMessage={() => t("no options")}
                        />
                      </div>
                      <div className={styles.task_action_group}>
                        <label className={styles.input_label_regular}>
                          {taskPrototypes.find(
                            (x) => x.id === taskAction.taskTemplateId
                          )?.recurrence
                            ? t("end recurrence after", "lower")
                            : t("task due period", "lower")}
                        </label>
                        <input
                          className={styles.text_input_margin}
                          type="number"
                          onInput={(e) =>
                            updateTaskActionEnd(
                              "CREATE",
                              Number(e.target.value),
                              index
                            )
                          }
                          disabled={readOnly}
                          defaultValue={
                            taskPrototypes.find(
                              (x) => x.id === taskAction.taskTemplateId
                            )?.recurrence && !taskAction.end
                              ? ""
                              : taskAction.end
                          }
                        />
                        <Select
                          styles={selectStyles}
                          menuPosition={"relative"}
                          isDisabled={readOnly}
                          value={taskActionDelayUnitsOptions.find(
                            (option) => option.value === taskAction.endUnit
                          )}
                          maxMenuHeight={200}
                          onChange={(selected) => {
                            updateTaskActionEndUnit(
                              "CREATE",
                              selected.value,
                              index
                            );
                          }}
                          options={taskActionDelayUnitsOptions}
                          placeholder={t("select")}
                          noOptionsMessage={() => t("no options")}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            )}

          {!readOnly && (
            <div className={styles.row_vertical}>
              <Button
                variant="orange"
                size="s"
                onClick={() => addNewTaskAction("CREATE")}
              >
                {t("add task to start")}
              </Button>
            </div>
          )}

          {!!ruleItem &&
            !!ruleItem.taskActions &&
            !!ruleItem.taskActions.filter((action) => action.type === "DELETE")
              .length && (
              <div className={styles.row}>
                <label className={styles.input_label}>
                  {t("choose task to be stopped")}
                </label>

                {ruleItem.taskActions
                  .filter((action) => action.type === "DELETE")
                  .map((taskAction, index) => (
                    <div className={styles.task_action_row} key={index}>
                      <div className={styles.task_action_group_space_between}>
                        <div className={styles.task_action_group}>
                          <Select
                            styles={selectStyles}
                            menuPosition={"relative"}
                            isDisabled={readOnly}
                            value={taskPrototypeOptions.find(
                              (option) =>
                                option.value === taskAction.taskTemplateId
                            )}
                            maxMenuHeight={200}
                            onChange={(selected) =>
                              updateTaskActionId(
                                "DELETE",
                                selected.value,
                                index
                              )
                            }
                            options={taskPrototypeOptions}
                            placeholder={t("select")}
                            noOptionsMessage={() => t("no options")}
                          />
                          <label className={styles.input_label_regular}>
                            {t("delay stop by", "lower")}
                          </label>
                          <input
                            className={styles.text_input_margin}
                            type="number"
                            onInput={(e) =>
                              updateTaskActionDelay(
                                "DELETE",
                                Number(e.target.value),
                                index
                              )
                            }
                            disabled={readOnly}
                            defaultValue={taskAction.delay}
                          />
                          <Select
                            styles={selectStyles}
                            menuPosition={"relative"}
                            isDisabled={readOnly}
                            value={taskActionDelayUnitsOptions.find(
                              (option) => option.value === taskAction.delayUnit
                            )}
                            maxMenuHeight={200}
                            onChange={(selected) => {
                              updateTaskActionDelayUnit(
                                "DELETE",
                                selected.value,
                                index
                              );
                            }}
                            options={taskActionDelayUnitsOptions}
                            placeholder={t("select")}
                            noOptionsMessage={() => t("no options")}
                          />
                        </div>
                        {!readOnly && (
                          <div
                            className={styles.delete_task_action_btn}
                            onClick={() => deleteTaskAction("DELETE", index)}
                          >
                            <Icon name="x-delete" />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            )}

          {!readOnly && (
            <div className={styles.row_vertical}>
              <Button
                variant="orange"
                size="s"
                onClick={() => addNewTaskAction("DELETE")}
              >
                {t("add task to stop")}
              </Button>
            </div>
          )}

          <div className={styles.row_vertical}>
            <input
              id="critical"
              name="critical"
              type="checkbox"
              className={styles.checkbox}
              onChange={(e) =>
                setRuleItem({ ...ruleItem, critical: e.target.checked })
              }
              checked={ruleItem.critical}
              disabled={readOnly}
            />
            <label
              className={
                readOnly ? styles.input_label : styles.input_label_checkbox
              }
              htmlFor={"critical"}
            >
              {t("rule was created in response to something being wrong")}
            </label>
          </div>

          {ruleItem.critical && (
            <div className={styles.row}>
              <label className={styles.input_label}>
                {t("choose criticality")}
              </label>
              <Select
                styles={selectStyles}
                menuPosition={"relative"}
                isDisabled={readOnly}
                value={criticalityOptions.find(
                  (option) => option.value === ruleItem.alertLevel
                )}
                maxMenuHeight={200}
                onChange={(selected) => {
                  setRuleItem({ ...ruleItem, alertLevel: selected.value });
                }}
                options={criticalityOptions}
                placeholder={t("select")}
                noOptionsMessage={() => t("no options")}
              />
            </div>
          )}

          <div className={styles.row_vertical}>
            <input
              id="sendNotifications"
              name="sendNotifications"
              type="checkbox"
              className={styles.checkbox}
              onChange={(e) =>
                setRuleItem({
                  ...ruleItem,
                  sendNotifications: e.target.checked,
                })
              }
              checked={ruleItem.sendNotifications}
              disabled={readOnly}
            />
            <label
              className={
                readOnly ? styles.input_label : styles.input_label_checkbox
              }
              htmlFor={"sendNotifications"}
            >
              {t("inform users")}
            </label>
          </div>

          {!readOnly && (
            <div className={!rule ? styles.buttons : styles.buttons_right}>
              {!rule && (
                <Button onClick={() => cancelMethod()} variant="ghost" size="s">
                  {t("cancel")}
                </Button>
              )}
              <Button
                onClick={() => {
                  if (!ruleItem.name) {
                    setShowNameError(true);
                  } else if (ruleNames.includes(ruleItem.name)) {
                    setShowNameUniqueError(true);
                  } else {
                    !rule ? createRule(ruleItem) : editRule(rule.id, ruleItem);
                  }
                }}
                variant="orange"
                size="s"
              >
                {!rule ? t("add rule") : t("update rule")}
              </Button>
            </div>
          )}
        </>
      )}

      {!!showRuleDeleteConfirmationModal && (
        <DeleteRuleModal
          onClose={() => setShowRuleDeleteConfirmationModal(false)}
          isOpen={showRuleDeleteConfirmationModal}
          rule={rule}
          onDelete={() => deleteRule(rule.id)}
        />
      )}

      {!!showRuleDisableConfirmationModal && (
        <DisableRuleModal
          onClose={() => setShowRuleDisableConfirmationModal(false)}
          isOpen={showRuleDisableConfirmationModal}
          rule={rule}
          onConfirm={() => {
            editRule(rule.id, { ...rule, isDisabled: true });
            setShowRuleDisableConfirmationModal(false);
          }}
        />
      )}

      {!!showRuleEnableConfirmationModal && (
        <DisableRuleModal
          enable
          onClose={() => setShowRuleEnableConfirmationModal(false)}
          isOpen={showRuleEnableConfirmationModal}
          rule={rule}
          onConfirm={() => {
            editRule(rule.id, { ...rule, isDisabled: false });
            setShowRuleEnableConfirmationModal(false);
          }}
        />
      )}
    </div>
  );
};

export default RuleItem;
