import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FieldInput, FieldSelect, FieldLabel, FormControl } from "../Input";
import styles from "./NumericalBuilder.module.scss";
import { fetchUnitsList } from "../../redux/units/unitActions";
import { I18nContext } from "../../i18n/I18nContext";

const NumericalBuilder = ({ range, name, property }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUnitsList());
  }, [dispatch]);

  const unitsOptions = useSelector((state) =>
    state.units.items.length
      ? state.units.items
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((u) => ({ label: u.name, value: u.id }))
      : []
  );

  return (
    <>
      {range && (
        <>
          {/*<div className={styles.row}>*/}
          {/*  <FormControl>*/}
          {/*    <FieldLabel>{t("value")}</FieldLabel>*/}
          {/*    <FieldInput*/}
          {/*      type="number"*/}
          {/*      placeholder="Value"*/}
          {/*      size="m"*/}
          {/*      // name="selected"*/}
          {/*      name={name ? `${name}.selected` : "selected"}*/}
          {/*      disabled*/}
          {/*    />*/}
          {/*  </FormControl>*/}
          {/*</div>*/}
          <div className={styles.row}>
            <FormControl>
              <FieldLabel>{t("min")}</FieldLabel>
              <FieldInput
                size="xs"
                type="number"
                placeholder="Min"
                // name="lowerBound"
                name={name ? `${name}.lowerBound` : "lowerBound"}
              />
            </FormControl>
            <div className={styles.indicator}>-</div>
            <FormControl>
              <FieldLabel>{t("max")}</FieldLabel>
              <FieldInput
                size="xs"
                type="number"
                placeholder="Max"
                // name="upperBound"
                name={name ? `${name}.upperBound` : "upperBound"}
              />
            </FormControl>
            <div className={styles.indicator}></div>
            <FormControl className={styles.units_container}>
              <FieldLabel>{t("units")}</FieldLabel>
              <FieldSelect
                placeholder="Units"
                size="s"
                // name="unit"
                name={name ? `${name}.unit` : "unit"}
                options={unitsOptions}
              />
            </FormControl>
          </div>
        </>
      )}
      {!range && (
        <div className={styles.row}>
          {/*<FormControl>*/}
          {/*  <FieldLabel>{t("number")}</FieldLabel>*/}
          {/*  <FieldInput*/}
          {/*    type="number"*/}
          {/*    placeholder="Number"*/}
          {/*    size="m"*/}
          {/*    // name="selected"*/}
          {/*    name={name ? `${name}.selected` : "selected"}*/}
          {/*    disabled*/}
          {/*  />*/}
          {/*</FormControl>*/}
          <FormControl className={styles.units_container}>
            <FieldLabel>{t("units")}</FieldLabel>
            <FieldSelect
              placeholder="Units"
              size="s"
              // name="unit"
              name={name ? `${name}.unit` : "unit"}
              options={unitsOptions}
            />
          </FormControl>
        </div>
      )}
    </>
  );
};

export default NumericalBuilder;
