import React, { useContext } from "react";
import * as Yup from "yup";
import Modal from "../Modal";
import {
  FieldError,
  FieldInput,
  FieldLabel,
  Form,
  FormControl,
} from "../Input";
import Button from "../Button";
import styles from "./AddUnitsModal.module.scss";
import { useDispatch } from "react-redux";
import {
  addUnit,
  editUnit,
  fetchUnitsList,
} from "../../redux/units/unitActions";
import { I18nContext } from "../../i18n/I18nContext";

const AddUnitsModal = ({ onClose, isOpen, unitsEdit, unit }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();

  const initialValues = {
    name: unit?.name ?? "",
    symbol: unit?.symbol ?? "",
    max: unit?.range?.upperBound ?? "",
    min: unit?.range?.lowerBound ?? "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t("missing unit name")),
    symbol: Yup.string().required(t("missing symbol name")),
    min: Yup.number()
      .required(t("missing minimum value"))
      .max(1000000, t("value cannot exceed 1000000")),
    max: Yup.number()
      .required(t("missing maximum value"))
      .max(1000000, t("value cannot exceed 1000000")),
  });

  const clearForm = (formik) => {
    formik?.resetForm();
  };

  const handleClose = () => {
    clearForm();
    onClose();
  };
  const handleSubmit = async (values, formik) => {
    if (unit) {
      await dispatch(
        editUnit(t, {
          id: unit.id,
          name: values.name,
          symbol: values.symbol,
          range: {
            lowerBound: values.min,
            step: 0,
            upperBound: values.max,
          },
        })
      );
    } else {
      await dispatch(
        addUnit(t, {
          name: values.name,
          symbol: values.symbol,
          range: {
            lowerBound: values.min,
            step: 0,
            upperBound: values.max,
          },
        })
      );
    }
    dispatch(fetchUnitsList());
    clearForm(formik);
    onClose();
  };
  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      classNameTitle={styles.title}
      title={unitsEdit ? t("edit unit") : t("add new unit")}
      form="unitId"
      divider={false}
      footer={
        <div className={styles.button_section}>
          <Button
            onClick={handleClose}
            type="reset"
            form={"unitId"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          {!unitsEdit && (
            <Button
              className={styles.btn_separator}
              type="submit"
              variant="orange"
              size="s"
              form={"unitId"}
            >
              {t("add")}
            </Button>
          )}

          {unitsEdit && (
            <Button
              className={styles.btn_separator}
              type="submit"
              variant="orange"
              size="s"
              form={"unitId"}
            >
              {t("save changes")}
            </Button>
          )}
        </div>
      }
    >
      <Form
        id="unitId"
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <FormControl className={styles.dropdown}>
          <FieldLabel>
            {t("name")}
            <span className={styles.red}>*</span>
          </FieldLabel>
          <FieldInput name="name" size="l" placeholder="Unit name" />
          <FieldError name="name" />
        </FormControl>
        <FormControl className={styles.dropdown}>
          <FieldLabel>
            {t("short")}
            <span className={styles.red}>*</span>
          </FieldLabel>
          <FieldInput name="symbol" size="l" placeholder="Short name" />
          <FieldError name="symbol" />
        </FormControl>
        <FormControl className={styles.dropdown}>
          <FieldLabel>
            {t("min value")}
            <span className={styles.red}>*</span>
          </FieldLabel>
          <FieldInput
            name="min"
            size="l"
            placeholder={t("min value")}
            type="number"
          />
          <FieldError name="min" />
        </FormControl>
        <FormControl className={styles.dropdown}>
          <FieldLabel>
            {t("max value")}
            <span className={styles.red}>*</span>
          </FieldLabel>
          <FieldInput
            name="max"
            size="l"
            placeholder={t("max value")}
            type="number"
          />
          <FieldError name="max" />
        </FormControl>
      </Form>
    </Modal>
  );
};

export default AddUnitsModal;
