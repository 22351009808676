import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import TaskContent from "./TaskContent";
import Properties from "./Properties";
import AttachedFiles from "./AttachedFiles";
import Icon from "../Icon";
import styles from "./AssetDetailsContent.module.scss";
// import QrCode from "./QrCode";
import AlertsList from "./AlertsList";

import { sensorDataMock } from "./__mockData__/mockAssetData";
import TaskFilteredDropdown from "./TaskFilteredDropdown";
import { I18nContext } from "../../i18n/I18nContext";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "../Accordion";
import {
  fetchTasksPrototypesList,
  getSensorsMeasurements,
  detachSensor,
  editAsset,
  fetchRecurrenceTasksForAsset,
  getRecurrenceTasks,
  fetchAlerts,
} from "../../redux";
import SensorModal from "./AddSensorModal";
import DetachSensorModal from "./DetachSensorModal";
import SensorChartsView from "./SensorChartsView";
import { updateAssetProperties } from "../PrototypeForm";
import UsersAccess from "./UsersAccess";
import axios from "../../redux/auth";
import { toLayoutConfig, viewerConfig } from "../LayoutViewer/LayoutUtils";
import CanvasFacade from "../LayoutViewer/Viewer";
import Loader from "../Loader";
import cx from "classnames";
import OneOfTaskModal from "../Tasks/OneOfTaskModal";
import RecurringTaskModal from "../Tasks/RecurringTaskModal";
import RecurrenceTasks from "../Tasks/RecurrenceTasks";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FilterDropdown } from "../Dropdown";
import { MeatballTask } from "./index";

const getUniqueItemsByProperties = (items, propName) => {
  return items.filter(
    (item, index, array) =>
      index ===
      array.findIndex((foundItem) => foundItem[propName] === item[propName])
  );
};

const AssetDetailsContent = ({ assetDetails, getAssetDetails }) => {
  const { t } = useContext(I18nContext);
  const viewer = useRef();
  const dispatch = useDispatch();
  const [layout, setLayout] = useState();
  const [pending, setPending] = useState(true);
  const [currentDetails, setAssetDetails] = useState(null);
  const [openTasksItems, setOpenTasksItems] = useState([]);
  const [closedTasksItems, setClosedTasksItems] = useState([]);
  const [sensorData, setSensorData] = useState([]);
  const [filteredOpenTasksItems, setFilteredOpenTasksItems] = useState([]);
  const [filteredRecurrenceItems, setFilteredRecurrenceItems] = useState([]);
  const [recurrenceTaskTypeFilter, setRecurrenceTaskTypeFilter] = useState([]);
  const [recurrenceTaskStateFilter, setRecurrenceTaskStateFilter] = useState(
    []
  );
  const [filteredClosedTasksItems, setFilteredClosedTasksItems] = useState([]);
  const [openAddSensorModal, setOpenAddSensorModal] = useState(false);
  const [sensorsMeasurments, setSensorsMeasurments] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [openDetachSensorModal, setOpenDetachSensorModal] = useState(false);
  const [sensorToDetach, setSensorToDetach] = useState(null);
  const [openOneOfTaskModal, setOpenOneOfTaskModal] = useState(false);
  const [openRecurringTaskModal, setOpenRecurringTaskModal] = useState(false);
  const [alerts, setAlerts] = useState([]);

  const recurrenceItems = useSelector(getRecurrenceTasks);

  const recurrenceTaskTemplateOptions =
    recurrenceItems
      ?.map(({ taskPrototype }) => {
        return {
          value: taskPrototype.id,
          label: taskPrototype.name,
          global: taskPrototype.global,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const recurrenceTaskStateOptions = [
    {
      value: "pending",
      label: t("pending"),
    },
    {
      value: "started",
      label: t("started"),
    },
    {
      value: "finished",
      label: t("finished group task"),
    },
    {
      value: "suspended",
      label: t("suspended"),
    },
  ];

  useEffect(() => {
    setOpenTasksItems(assetDetails.recentTasks);
    setClosedTasksItems(
      assetDetails.readyTasks.sort((a, b) =>
        a.history.at(-1).occurredAt.localeCompare(b.history.at(-1).occuredAt)
      )
    );
    setFilteredOpenTasksItems(assetDetails.recentTasks);
    setFilteredClosedTasksItems(
      assetDetails.readyTasks.sort((a, b) =>
        a.history.at(-1).occurredAt.localeCompare(b.history.at(-1).occuredAt)
      )
    );
  }, [dispatch, assetDetails]);

  useEffect(() => {
    if (currentDetails) {
      handleFetchSensorMeasurments();
    }
  }, [currentDetails]);

  useEffect(() => {
    dispatch(fetchTasksPrototypesList());
  }, []);

  useDeepCompareEffect(() => {
    dispatch(
      fetchRecurrenceTasksForAsset(
        assetDetails.id,
        recurrenceTaskTypeFilter
          ? recurrenceTaskTypeFilter.map((option) => option.value)
          : [],
        recurrenceTaskStateFilter
          ? recurrenceTaskStateFilter.map((option) => option.value)
          : []
      )
    );
  }, [currentDetails, recurrenceTaskTypeFilter, recurrenceTaskStateFilter]);

  useEffect(() => {
    // dispatch(fetchSensorData(assetId));
    setSensorData(sensorDataMock);
  }, []);

  useEffect(() => {
    setAssetDetails(assetDetails);
  }, [assetDetails]);

  const handleUpdate = (partial) => {
    setAssetDetails({
      ...assetDetails,
      ...partial,
    });
  };

  useEffect(() => {
    if (
      !!currentDetails &&
      !currentDetails.topLevel &&
      !currentDetails.grouping &&
      currentDetails.parentHaveLayout
    ) {
      viewer.current = new CanvasFacade(viewerConfig);
      viewer.current.mount(document.querySelector("#canvas2"));
      getLayout();
      return () => viewer.current.unmount();
    } else {
      setPending(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDetails]);

  useEffect(() => {
    setFilteredRecurrenceItems(recurrenceItems);
  }, [recurrenceItems]);

  useEffect(() => {
    handleFetchAlerts();
  }, [currentDetails]);

  const handleFetchAlerts = async () => {
    const { data } = await dispatch(fetchAlerts(assetDetails.id));
    setAlerts(data);
  };

  const getUrlsForKeys = async (keys) => {
    const { data } = await axios.post("/files", keys);
    return data;
  };

  const getLayout = async () => {
    setPending(true);
    const { data } = await axios.get(
      `/assets/${currentDetails.parentId}/layout`
    );
    let urls = await getUrlsForKeys([data.key]);
    data.layoutUrl = urls[0];
    setLayout(data);

    await viewer.current.loadLayout(toLayoutConfig(data));
    setPending(false);
  };

  const calculateCanvasPosition = (layoutData) => {
    if (!layoutData) return {};
    const poi = layoutData.pois.find(
      (poi) => poi.objectOfInterestId === assetDetails.id
    );
    const contentBoxWidth =
      document.getElementById("layout_content_box").offsetWidth;
    const contentBoxHeight =
      document.getElementById("layout_content_box").offsetHeight;
    const canvas = document.getElementById("canvas2").firstChild;
    const canvasWidth = canvas.offsetWidth;
    const canvasHeight = canvas.offsetHeight;
    const layoutCenterX = layoutData.layoutWidth / 2;
    const layoutCenterY = layoutData.layoutHeight / 2;
    const ratioX = layoutData.layoutWidth / canvasWidth;
    const ratioY = layoutData.layoutHeight / canvasHeight;
    const scaledPoiX = (layoutCenterX + poi?.location?.x) / ratioX;
    const scaledPoiY = (layoutCenterY - poi?.location?.y) / ratioY;
    const xPosition = contentBoxWidth / 2 - scaledPoiX;
    const yPosition = contentBoxHeight / 2 - scaledPoiY;
    return { left: `${xPosition}px`, top: `${yPosition}px` };
  };

  const handleFetchSensorMeasurments = async () => {
    if (!currentDetails.sensorIds) {
      return;
    }
    if (currentDetails.sensorIds.length <= 0) {
      return;
    }
    const response = await getSensorsMeasurements(assetDetails.id);
    setSensorsMeasurments(response.data);
  };

  const handleDetachSensor = async (id) => {
    try {
      const response = await detachSensor(id);

      if (response) {
        handleFetchSensorMeasurments();
      }
      setOpenDetachSensorModal(false);
      setSensorToDetach(null);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChartName = (index, name) => {
    sensorData[index].name = name;
    setSensorData([...sensorData]);
  };

  const handleFilteredOpenTasksChanged = useCallback(
    (openTasks) => {
      setFilteredOpenTasksItems(openTasks);
    },
    [setFilteredOpenTasksItems]
  );

  const handleFilteredClosedTasksChanged = useCallback(
    (closedTasks) => {
      setFilteredClosedTasksItems(closedTasks);
    },
    [setFilteredClosedTasksItems]
  );

  const handleOpenAddSensorModal = () => {
    setOpenAddSensorModal(true);
  };

  const handleCloseAddSensorModal = () => {
    handleFetchSensorMeasurments();
    setSelectedSensor(null);
    setOpenAddSensorModal(false);
  };

  const handleOpenEditModal = (sensorId) => {
    setSelectedSensor(sensorId);
    setOpenAddSensorModal(true);
  };

  const handleUpdateAssetProperties = async (values) => {
    const propertiesCopy = [...currentDetails?.assetProperties.properties].map(
      (prop) => (prop.current ? prop.current : prop)
    );

    await dispatch(
      editAsset(t, assetDetails.id, {
        ...currentDetails,
        assetProperties: {
          ...currentDetails.assetProperties,
          properties: updateAssetProperties(propertiesCopy, values),
        },
      })
    );
  };

  const accordionItemClassNames = cx({
    [styles.accordion_top]: true,
  });

  return (
    <div className={styles.row}>
      <div className={styles.main_container}>
        {pending && <Loader />}

        <Accordion>
          <AccordionItem
            key={"open_tasks"}
            id={"open_tasks"}
            className={accordionItemClassNames}
          >
            <AccordionHeader
              text={`${t("recent tasks")} (${filteredOpenTasksItems?.length})`}
              className={styles.accordion_header}
              hasItem={!!openTasksItems?.length}
              plusItem={
                <div className={styles.add_button}>
                  <MeatballTask currentDetails={currentDetails} />
                </div>
              }
            ></AccordionHeader>
            <AccordionPanel>
              <div className={styles.accordion_content}>
                <div className={styles.task_filters_container}>
                  <TaskFilteredDropdown
                    onItemsFiltered={handleFilteredOpenTasksChanged}
                    items={openTasksItems}
                    openTasks
                  />
                </div>
                <TaskContent
                  items={filteredOpenTasksItems}
                  assetId={assetDetails.id}
                  getAssetDetails={getAssetDetails}
                  assetDetailsTaskList
                />
              </div>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Accordion>
          <AccordionItem
            key={"recurring_tasks"}
            id={"recurring_tasks"}
            className={accordionItemClassNames}
          >
            <AccordionHeader
              text={`${t("recurrence tasks")} (${
                filteredRecurrenceItems?.length
              })`}
              className={styles.accordion_header}
              hasItem={!!recurrenceItems?.length}
              plusItem={
                <div
                  className={styles.add_button}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenRecurringTaskModal(true);
                  }}
                >
                  <Icon name="add" className={styles.icon} />
                </div>
              }
            ></AccordionHeader>
            <AccordionPanel>
              <div className={styles.accordion_content}>
                <div className={styles.filters}>
                  <FilterDropdown
                    value={recurrenceTaskStateFilter}
                    options={recurrenceTaskStateOptions}
                    onSelect={(value) => setRecurrenceTaskStateFilter(value)}
                    placeholder={t("state")}
                    isMultiple={true}
                    wide
                  />
                  <FilterDropdown
                    value={recurrenceTaskTypeFilter}
                    options={getUniqueItemsByProperties(
                      recurrenceTaskTemplateOptions,
                      "value"
                    )}
                    onSelect={(value) => setRecurrenceTaskTypeFilter(value)}
                    placeholder={t("task template")}
                    isMultiple={true}
                    wide
                  />
                </div>
                <div className={styles.recurence_content}>
                  <RecurrenceTasks
                    items={filteredRecurrenceItems}
                    filters={{
                      taskTypeFilter: recurrenceTaskTypeFilter.map(
                        (option) => option.value
                      ),
                      statusFilter: recurrenceTaskStateFilter.map(
                        (option) => option.value
                      ),
                      sort: "ASSET_PATH",
                    }}
                  />
                </div>
              </div>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Accordion>
          <AccordionItem
            key={"closed_tasks"}
            id={"closed_tasks"}
            className={accordionItemClassNames}
          >
            <AccordionHeader
              text={`${t("ready tasks")} (${filteredClosedTasksItems?.length})`}
              className={styles.accordion_header}
              hasItem={!!closedTasksItems?.length}
            ></AccordionHeader>
            <AccordionPanel>
              <div className={styles.accordion_content}>
                <div className={styles.task_filters_container}>
                  <TaskFilteredDropdown
                    onItemsFiltered={handleFilteredClosedTasksChanged}
                    items={closedTasksItems}
                    closedTasks
                  />
                </div>
                <TaskContent
                  items={filteredClosedTasksItems}
                  assetId={assetDetails.id}
                  disabled
                  getAssetDetails={getAssetDetails}
                  assetDetailsTaskList
                />
              </div>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        {assetDetails.grouping && (
          <div className={styles.contained_cell}>
            <UsersAccess assetDetails={assetDetails} />
          </div>
        )}

        {/*{!assetDetails.grouping && (*/}
        {/*  <span className={styles.contained_cell}>*/}
        {/*    <div className={styles.task_container}>*/}
        {/*      <div className={styles.btn}>*/}
        {/*        {t("sensors")} ({sensorsMeasurments.length})*/}
        {/*      </div>*/}
        {/*      <div*/}
        {/*        onClick={handleOpenAddSensorModal}*/}
        {/*        className={styles.addSensorButton}*/}
        {/*      >*/}
        {/*        <Icon name="add" className={styles.icon} />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <span className={styles.charts_container}>*/}
        {/*      {sensorsMeasurments.map((sensor, index) => (*/}
        {/*        <SensorChartsView*/}
        {/*          key={index}*/}
        {/*          sensor={sensor}*/}
        {/*          onOpenEdit={() => handleOpenEditModal(sensor.sensorId)}*/}
        {/*          onDetach={() => {*/}
        {/*            setSensorToDetach(sensor.sensorId);*/}
        {/*            setOpenDetachSensorModal(true);*/}
        {/*          }}*/}
        {/*        />*/}
        {/*      ))}*/}
        {/*    </span>*/}
        {/*  </span>*/}
        {/*)}*/}
      </div>

      <div className={styles.main_right}>
        {!!currentDetails &&
          !currentDetails.topLevel &&
          !currentDetails.grouping &&
          currentDetails.parentHaveLayout && (
            <div
              id="layout_content_box"
              className={styles.cell_right}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                className={styles.viewerScreen}
                id="canvas2"
                style={calculateCanvasPosition(layout)}
              />
            </div>
          )}

        <Accordion>
          <AccordionItem
            key={"alerts"}
            id={"alerts"}
            className={accordionItemClassNames}
          >
            <AccordionHeader
              text={`${t("alerts", "title")} (${alerts?.length})`}
              className={styles.accordion_header}
              hasItem={!!alerts?.length}
            ></AccordionHeader>
            <AccordionPanel>
              <AlertsList alerts={alerts} fetchAlerts={handleFetchAlerts} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <div className={styles.contained_cell}>
          <AttachedFiles
            currentDetails={currentDetails}
            attachments={currentDetails?.attachments.filter(
              (file) => file.isSignificant
            )}
            onUpdate={handleUpdate}
          />
        </div>

        <Accordion>
          <AccordionItem
            key={"properties"}
            id={"properties"}
            className={accordionItemClassNames}
          >
            <AccordionHeader
              text={`${t("properties", "title")} (${
                currentDetails?.assetProperties?.properties.length
              })`}
              className={styles.accordion_header}
              hasItem={!!currentDetails?.assetProperties?.properties.length}
            ></AccordionHeader>
            <AccordionPanel>
              <Properties
                assetProperties={currentDetails?.assetProperties}
                onUpdate={handleUpdateAssetProperties}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        {/*{!assetDetails.grouping && (*/}
        {/*  <>*/}
        {/*    <span className={styles.contained_cell}>*/}
        {/*      <QrCode*/}
        {/*        qrCodeText={currentDetails?.qrCode}*/}
        {/*        assetName={currentDetails?.name}*/}
        {/*      />*/}
        {/*    </span>*/}
        {/*  </>*/}
        {/*)}*/}
      </div>
      {!assetDetails.grouping && (
        <SensorModal
          isOpen={openAddSensorModal}
          onClose={handleCloseAddSensorModal}
          assetId={currentDetails?.id}
          sensor={selectedSensor}
        />
      )}
      {!assetDetails.grouping && (
        <DetachSensorModal
          isOpen={openDetachSensorModal}
          onClose={() => {
            handleFetchSensorMeasurments();
            setSensorToDetach(null);
            setOpenDetachSensorModal(false);
          }}
          onDetachSensor={() => handleDetachSensor(sensorToDetach)}
        />
      )}
      {!!openOneOfTaskModal && (
        <OneOfTaskModal
          isOpen={openOneOfTaskModal}
          currentDetails={currentDetails}
          onClose={() => setOpenOneOfTaskModal(false)}
        />
      )}
      {!!openRecurringTaskModal && (
        <RecurringTaskModal
          isOpen={openRecurringTaskModal}
          currentDetails={currentDetails}
          onClose={() => setOpenRecurringTaskModal(false)}
        />
      )}
    </div>
  );
};
export default AssetDetailsContent;
