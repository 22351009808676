import axios from "../auth";
import Actions from "../actions";
import history from "../../history";
import { appUrls } from "../../urls";
import { createToast } from "../toast/toastActions";
import jwtDecode from "jwt-decode";

export const loginSuccess = (info) => {
  return {
    type: Actions.LOGIN_SUCCESS,
    payload: info,
  };
};

export const loginSuccessForDeleting = (info) => {
  return {
    type: Actions.LOGIN_SUCCESS_FOR_DELETING,
    payload: info,
  };
};

export const refreshSuccess = (info) => {
  return {
    type: Actions.REFRESH_SUCCESS,
    payload: info,
  };
};

export const logoutSuccess = () => {
  return {
    type: Actions.LOGOUT,
  };
};

const loginError = (error) => {
  return {
    type: Actions.LOGIN_ERROR,
    payload: error,
  };
};

export const login = (t, payload) => {
  return async (dispatch) => {
    return axios
      .post("/auth/login", payload)
      .then(({ data }) => {
        if (data.status === "NOT_ACTIVE") {
          history.push(appUrls.LOGIN);
          dispatch(
            createToast({
              type: "error",
              title: "Error. ",
              description: t("account is not active"),
            })
          );
          return;
        }
        if (data.status === "DELETED") {
          history.push(appUrls.LOGIN);
          dispatch(
            createToast({
              type: "error",
              title: "Error. ",
              description: t("account is deleted"),
            })
          );
          return;
        }
        if (data.status === "FOR_DELETING") {
          dispatch(loginSuccessForDeleting(data));
          history.push(appUrls.CANCELDELETING);
          return;
        }
        dispatch(loginSuccess(data));
        localStorage.setItem("rm", JSON.stringify(payload.rememberMe));
        if (jwtDecode(data.token).isAuthorized) {
          sessionStorage.setItem("tapperSession", JSON.stringify(true));
        }
        history.push(
          jwtDecode(data.token).isAuthorized
            ? appUrls.COMPANY
            : appUrls.VALIDATE_LOGIN
        );
      })
      .catch((error) => {
        dispatch(loginError(error));
      });
  };
};

export const validateLogin = (t, payload, hasApp) => {
  return async (dispatch) => {
    return axios
      .post("/verify", payload)
      .then(({ data }) => {
        dispatch(loginSuccess(data));
        sessionStorage.setItem("tapperSession", JSON.stringify(true));
        history.push(hasApp ? appUrls.COMPANY : appUrls.INSTALL_APP);
      })
      .catch((error) => {
        dispatch(loginError(error));
        if (error.response.status === 403) {
          dispatch(
            createToast({
              type: "error",
              title: t("provided validation code is invalid"),
            })
          );
        }
      });
  };
};

export const regenerateCode = (t) => {
  return async (dispatch) => {
    return axios
      .post("/verify/regenerate/code", { toMobile: false })
      .then(() => {
        dispatch(
          createToast({
            type: "success",
            title: t("code resent to email address"),
          })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          createToast({
            type: "error",
            title: "Error. ",
            description: t("could not generate new code"),
          })
        );
      });
  };
};

export const checkIfLoggedIn = (payload) => {
  return axios.post("/auth/rememberMe", payload);
};

export const refreshToken = () => {
  return axios.post("/auth/refreshToken");
};

export const logout = () => {
  return async (dispatch) => {
    return axios.post("/auth/logout").then(() => {
      dispatch(logoutSuccess());
    });
  };
};

const confirmAccountSuccess = (info) => {
  return {
    type: Actions.CONFIRM_ACCOUNT_SUCCESS,
    payload: info,
  };
};

const confirmAccountError = (error) => {
  return {
    type: Actions.CONFIRM_ACCOUNT_ERROR,
    payload: error,
  };
};

export const clearAuthError = () => {
  return {
    type: Actions.CLEAR_AUTH_ERROR,
  };
};

export const accountNotActivatedError = (state) => {
  return {
    type: Actions.ACCOUNT_NOT_ACTIVATED_ERROR,
    payload: state,
  };
};

export const confirmAccount = (token, password) => {
  const url = appUrls.VALIDATE_REGISTRATION + `/${token}`;
  return async (dispatch) => {
    return axios
      .put(`/auth/password/confirm/${token}`, { password })
      .then(({ data }) => {
        dispatch(confirmAccountSuccess(data));
        history.push(url);
      })
      .catch((error) => confirmAccountError(error));
  };
};

export const validateRegistration = (t, code, token) => {
  return async (dispatch) => {
    return axios
      .post(`/auth/password/activation/${token}?code=${code}`)
      .then(({ data }) => {
        dispatch(confirmAccountSuccess(data));
        sessionStorage.setItem("tapperSession", JSON.stringify(true));
        history.push(appUrls.LOGIN);
      })
      .catch((error) => {
        dispatch(loginError(error));
      });
  };
};

export const regenerateRegistrationCode = (t, token) => {
  return async (dispatch) => {
    return axios
      .post(`/auth/resendActivationCode?token=${token}`)
      .then(() => {
        dispatch(
          createToast({
            type: "success",
            title: "code resent to email address",
          })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          createToast({
            type: "error",
            title: "Error. ",
            description: t("could not generate new code"),
          })
        );
      });
  };
};

export const cancelDeletingAccount = (email) => {
  return axios.put(`/profile/cancelDeleting?email=${email}`);
};
