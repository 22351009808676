export const appUrls = {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot_password",
  RESET_PASSWORD: "/reset",
  CONFIRM_ACCOUNT: "/auth/password/confirm",
  VALIDATE_REGISTRATION: "/validateaccount",
  CHECK_EMAIL: "/email",
  VALIDATE_LOGIN: "/validate",
  INSTALL_APP: "/installapp",
  COMPANY: "/company",
  REGION: "/region",
  CANCELDELETING: "/canceldeleting",

  ASSETS: "/assets",
  ASSETS_DETAILS: "/assets/details/:assetId",
  SIDEBAR: "/sidebar",
  DASHBOARD: "/dashboard",
  TASK: "/task",
  CONFIGURATION: "/configuration",
  ACCESS: "/access",
  REPORTS: "/reports",
  LICENSE: "/license",
  SETTINGS: "/settings",
  SIDEBAR_SECTION: {
    SETTINGS_PROFILE: "/settings/profile",
    SETTINGS_DATA: "/settings/data",
    SETTINGS_PREFERENCES: "/settings/preferences",
  },
  HELP: "/help",
  PROFILE: "/profile",
};

export const outerUrls = {
  CONTACT_US: "https://www.kalsbeek.net/tapper",
};
