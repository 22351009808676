import Actions from "../actions";
import axios from "../auth";
import { createToast } from "../toast/toastActions";
import { refreshToken, refreshSuccess } from "../auth/authActions";
import { downloadFile } from "../../helpers/helpers";

const assetsLoadingStart = () => {
  return {
    type: Actions.ASSETS_LOADING_START,
  };
};

const assetsLoadingStop = () => {
  return {
    type: Actions.ASSETS_LOADING_STOP,
  };
};

const fetchAssetsSuccess = (id, assets) => {
  return {
    type: Actions.FETCH_ASSETS_SUCCESS,
    id,
    assets,
  };
};

const fetchSearchAssetsSuccess = (assets) => {
  return {
    type: Actions.FETCH_ASSETS_SEARCH_SUCCESS,
    assets,
  };
};

const fetchTopAssetsSuccess = (assets) => {
  return {
    type: Actions.FETCH_TOP_ASSETS_SUCCESS,
    assets,
  };
};

export const addAssetsSuccess = (id, asset) => {
  return {
    type: Actions.ADD_ASSET_SUCCESS,
    id,
    asset,
  };
};

export const fetchAssetsDetailsSuccess = (asset) => {
  return {
    type: Actions.FEATCH_ASSET_DETAILS_SUCCESS,
    asset,
  };
};

export const fetchAllAssetsRequest = (payload) => {
  return {
    type: Actions.FETCH_ALL_ASSETS_REQUEST,
    payload,
  };
};

export const clearSearchAssets = () => {
  return {
    type: Actions.CLEAR_SEARCH,
  };
};

const assetsNamesLoadingStart = () => {
  return {
    type: Actions.ASSETS_NAMES_LOADING_START,
  };
};

const fetchAssetsNamesSuccess = (data) => {
  return {
    type: Actions.FETCH_ASSETS_NAMES_SUCCESS,
    payload: data,
  };
};

const fetchAssetsNamesFailure = (error) => {
  return {
    type: Actions.FETCH_ASSETS_NAMES_FAILURE,
    payload: error,
  };
};

export const fetchAssets = (parentId = null) => {
  return (dispatch) => {
    const url = parentId ? `/assets?parentId=${parentId}` : `/assets`;
    dispatch(fetchAllAssetsRequest());

    return axios.get(url).then((response) => {
      const assets = response.data;
      if (parentId === null) {
        dispatch(fetchTopAssetsSuccess(assets));
      } else {
        dispatch(fetchAssetsSuccess(parentId, assets));
      }
    });
  };
};

export const fetchChosenAssets = (buildingIds = []) => {
  return (dispatch) => {
    dispatch(fetchAllAssetsRequest());

    return axios.post(`/assets`, buildingIds).then((response) => {
      const assets = response.data;
      dispatch(fetchTopAssetsSuccess(assets));
    });
  };
};

function clean(obj) {
  const shallowCopy = { ...obj };
  for (var propName in shallowCopy) {
    if (
      shallowCopy[propName] === null ||
      shallowCopy[propName] === undefined ||
      shallowCopy[propName] === ""
    ) {
      delete shallowCopy[propName];
    }
  }
  return shallowCopy;
}

export const fetchFilteredAssets = (filters) => {
  return (dispatch) => {
    return axios
      .post("/assets/node/search", clean(filters))
      .then((response) => {
        const assets = response.data;
        dispatch(fetchSearchAssetsSuccess(assets));
      });
  };
};

export const addAsset = (t, parentId, assetPrototypeId, asset) => {
  return (dispatch) => {
    dispatch(assetsLoadingStart());
    const url = parentId
      ? `/assetsFromPrototype?parentId=${parentId}&assetPrototypeId=${assetPrototypeId}`
      : `/assetsFromPrototype?&assetPrototypeId=${assetPrototypeId}`;

    return axios
      .post(url, asset)
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully created new asset"),
          })
        );
        dispatch(assetsLoadingStop());
        return response;
      })
      .catch((error) => {
        if (!parentId && error.response.status === 409) {
          dispatch(
            createToast({
              type: "error",
              title: t(
                "the license number is not sufficient to add more buildings"
              ),
            })
          );
          dispatch(assetsLoadingStop());
        }
      });
  };
};

export const editAssetSuccess = (assetId, asset) => {
  return {
    type: Actions.EDIT_ASSET_SUCCESS,
    payload: {
      assetId,
      asset,
    },
  };
};

export const editAsset = (t, assetId, asset) => {
  return async (dispatch) => {
    dispatch(assetsLoadingStart());
    const { data } = await axios.put(`/assets/${assetId}`, asset);
    if (data) {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully edited asset"),
        })
      );
      dispatch(assetsLoadingStop());
      return data;
    }
    dispatch(editAssetSuccess(assetId, data));
  };
};

export const fetchAssetsNameList = (buildingIds = []) => {
  return (dispatch) => {
    dispatch(assetsNamesLoadingStart());
    return axios
      .post(`/assets/signatures`, buildingIds)
      .then((response) => {
        dispatch(fetchAssetsNamesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchAssetsNamesFailure(error));
      });
  };
};

export const fetchAssetDetails = (assetId) => {
  return (dispatch) => {
    return axios
      .get(`/assets/${assetId}`)
      .then((response) => {
        dispatch(fetchAssetsDetailsSuccess(response.data));
      })
      .catch((error) => {
        // dispatch(fetchAssetsDetailsFailure(error));
      });
  };
};

export const deleteAsset = (t, assetId) => {
  return (dispatch) => {
    dispatch(assetsLoadingStart());
    return axios.delete(`/assets/${assetId}`).then(() => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully deleted asset"),
        })
      );
      dispatch(assetsLoadingStop());
    });
  };
};

export const toggleActivation = (t, assetId, isActive) => {
  return (dispatch) => {
    dispatch(stateChangeStart());
    return axios
      .put(`/assets/${assetId}/status?isActive=${isActive}`)
      .then(() => {
        dispatch(stateChangeStop());
        dispatch(
          createToast({
            type: "success",
            title: t("successfully changed activation of the asset"),
          })
        );
      });
  };
};

export const addAssetLayoutImage = (t, id, image) => {
  const formData = new FormData();
  formData.append("image", image, image.name);

  return (dispatch) => {
    return axios
      .post(`/assets/${id}/layout`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully added asset layout image"),
          })
        );
        return response;
      });
  };
};

export const addSingleAttachment = (t, id, file) => {
  const formData = new FormData();
  formData.append("file", file, file.name);

  return (dispatch) => {
    return axios
      .post(`/assets/${id}/attachment`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully uploaded attachment"),
          })
        );
      });
  };
};

export const deleteSingleAttachment = (t, id, key) => {
  return (dispatch) => {
    return axios.delete(`/assets/${id}/attachment?key=${key}`).then(() => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully deleted attachment"),
        })
      );
    });
  };
};

//Alerts
export const fetchAlerts = (assetId) => {
  return () => {
    const url = `/asset/${assetId}/alerts`;
    return axios.get(url);
  };
};

export const deactivateAlert = (alertId) => {
  return () => {
    const url = `/alerts/${alertId}`;
    return axios.delete(url);
  };
};

export const getAssetStates = async (id) => {
  let assetStates = [];
  await axios.get(`/assets/${id}/states`).then((response) => {
    assetStates = response.data;
  });

  return assetStates;
};

export const getAssetStateEvents = async (id) => {
  let assetStateEvents = [];
  await axios.get(`/assets/${id}/events`).then((response) => {
    assetStateEvents = response.data;
  });

  return assetStateEvents;
};

const stateChangeStart = () => {
  return {
    type: Actions.STATE_CHANGE_START,
  };
};

export const stateChangeStop = () => {
  return {
    type: Actions.STATE_CHANGE_STOP,
  };
};

export const updateAssetState = (t, id, state) => {
  return (dispatch) => {
    dispatch(stateChangeStart());
    return axios.put(`/assets/${id}/state?state=${state}`).then(() => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully updated asset state"),
        })
      );
      dispatch(fetchAssetDetails(id));
      dispatch(stateChangeStop());
    });
  };
};

export const deleteMultipleAttachments = (id) => {
  return () => {
    return axios.delete(`/assets/${id}/attachments`);
  };
};

export const exportAssetsTemplate = (t, prototypeIds) => {
  return (dispatch) => {
    return axios
      .post(`/assetPrototype/export`, { ids: prototypeIds })
      .then(({ data }) => {
        downloadFile(data);
      })
      .catch((error) => {
        dispatch(
          createToast({
            type: "error",
            title: t("exporting asset template failed"),
          })
        );
      });
  };
};

export const importBuildings = (t, values) => {
  const formData = new FormData();
  values.files.forEach((file) => {
    formData.append("file", file, file.name);
  });

  return async (dispatch) => {
    const { data } = await refreshToken();
    await dispatch(refreshSuccess(data));
    return axios
      .post(`/assets/roots/import`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch(fetchAssets());
        dispatch(
          createToast({
            type: "success",
            title: t("successfully send file"),
          })
        );
        if (response?.data?.file) {
          downloadFile(response.data.file);
        }
        return response;
      });
  };
};

export const importAssets = (t, values, rootId) => {
  const formData = new FormData();
  values.files.forEach((file) => {
    formData.append("file", file, file.name);
  });

  return async (dispatch) => {
    const { data } = await refreshToken();
    await dispatch(refreshSuccess(data));
    return axios
      .post(`/assets/import?rootId=${rootId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch(fetchAssets());
        if (response?.data?.importSuccess) {
          dispatch(
            createToast({
              type: "success",
              title: t("import completed"),
            })
          );
        }
        if (
          !response?.data?.allImportFailed &&
          !response?.data?.importSuccess
        ) {
          dispatch(
            createToast({
              type: "warning",
              title: t(
                "import completed with error: check download folder for errors summary"
              ),
            })
          );
        }
        if (response?.data?.allImportFailed && !response?.data?.importSuccess) {
          dispatch(
            createToast({
              type: "error",
              title: t(
                "import failed: check download folder for errors summary"
              ),
            })
          );
        }
        if (response?.data?.file) {
          downloadFile(response.data.file);
        }
        return response;
      });
  };
};

export const deleteAssetLayout = (t, id) => {
  return (dispatch) => {
    return axios.delete(`/assets/${id}/layout`).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully deleted asset layout"),
        })
      );
      return response;
    });
  };
};

export const selectAssetLayout = (layout) => {
  return {
    type: Actions.SELECT_LAYOUT,
    layout,
  };
};

export const fetchAssetsForGroupTasks = (
  assetPrototypeId,
  parentId,
  filters
) => {
  return (dispatch) => {
    const url = `/taskPool/assets/${assetPrototypeId}?parentId=${parentId}`;

    return axios
      .post(url, filters)
      .then((response) => {
        dispatch(fetchAssetsForGroupTaskSuccess(response.data));
        return response.data;
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };
};

export const fetchUnfilteredAssetsForGroupTasks = (
  assetPrototypeId,
  parentId,
  filters
) => {
  return (dispatch) => {
    const url = `/taskPool/assets/${assetPrototypeId}?parentId=${parentId}`;

    return axios
      .post(url, filters)
      .then((response) => {
        dispatch(fetchUnfilteredAssetsForGroupTaskSuccess(response.data));
        return response.data;
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };
};

const fetchAssetsForGroupTaskSuccess = (assets) => {
  return {
    type: Actions.FETCH_ASSETS_FOR_GROUP_TASK_SUCCESS,
    assets,
  };
};

const fetchUnfilteredAssetsForGroupTaskSuccess = (assets) => {
  return {
    type: Actions.FETCH_UNFILTERED_ASSETS_FOR_GROUP_TASK_SUCCESS,
    assets,
  };
};
